import { types as t, cast, flow, Instance, getParent } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { requests } from '../api/api';

const ApiKeysModel = t.model('ApiKeysModel', {
	key: t.identifierNumber,
	apiKey: t.string,
	status: t.string,
});

export type IApiKeysModel = Instance<typeof ApiKeysModel>;

const ApiKeysStore = t
	.model('ApiKeys', {
		isApiKeysLoading: t.boolean,
		apiKeysList: t.array(ApiKeysModel),
	})
	.views((self) => ({
		get mobxStore() {
			return getParent(self);
		},
		get storedApiKeysList() {
			return toJS(self.apiKeysList);
		},
	}))
	.actions((self) => {
		const setIsApiKeysLoading = (status: boolean) => {
			self.isApiKeysLoading = status;
		};
		const setApiKeysList = (items: string[]) => {
			const apiKeys = items.map((item: string, index: number) => ({
				key: index + 1,
				apiKey: item,
				status: 'Active',
			}));

			self.apiKeysList = cast(apiKeys);
		};

		const getApiKeys = flow(function* () {
			setIsApiKeysLoading(true);
			try {
				const apiKeys = yield requests.getApiList();
				setApiKeysList(apiKeys.data);

				setIsApiKeysLoading(false);
				return { status: 200 };
			} catch (error) {
				setIsApiKeysLoading(false);
				return { status: 500 };
			}
		});

		return { setIsApiKeysLoading, setApiKeysList, getApiKeys };
	});

export default ApiKeysStore;
export type IApiKeysStore = Instance<typeof ApiKeysStore>;

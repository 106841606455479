import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import List from 'antd/es/list';
import Button from 'antd/es/button';
import notification from 'antd/es/notification';

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';

import { UserAvatar } from '../../molecules/user-avatar';
import { useStore } from '../../../services/stores/use-store';
import { withRouter } from '../../../services/hooks/withRouter';

import s from './team-list.module.scss';

export type TeamListProps = {
	onAdd(): void;
}
const TeamList = ({ onAdd }: TeamListProps) => {
	const { teamStore, authStore } = useStore();

	const onRemove = async (user: {email:string, id: string}) => {
		try {
			await teamStore.removeMember(user.id);
			notification.success({
				message: 'Success',
				description: `User ${user.email} has been removed.`,
			});
		} catch (e) {
			notification.error({
				message: 'Error',
				description: `Can not remove user ${user.email}.`,
			});
		}
	}

	return (
		<List
			className={s.withOutBorder}
			itemLayout="horizontal"
			header={<ListHeader onInvite={onAdd} />}
			dataSource={teamStore.members}
			loading={teamStore.isTeamLoading}
			renderItem={(item) => (
				<List.Item>
					<List.Item.Meta
						className={cn({ [s.withOutIcon]: true })}
						avatar={<UserAvatar size={24} email={item.email} />}
						title={item.email}
						description={item.email}
					/>
					{authStore.user?.id !== item.id && (<Button icon={<DeleteOutlined/>} title="Remove" onClick={() => onRemove(item)}/>)}
				</List.Item>
			)}
		/>
	);
};

type ListHeaderProps = {
	onInvite(): void
}
function ListHeader({ onInvite }: ListHeaderProps) {
	return (
		<Button onClick={onInvite} icon={<PlusOutlined />}>Invite</Button>
	)
}

export default withRouter(observer(TeamList));

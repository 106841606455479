import s from './project-item.module.scss';
import ProjectStatistic from '../../molecules/project-statistic';
import { UploadExport } from '../../molecules/upload-export';
import { ProjectTable } from '../../atoms/project-table';
import Graph from '../../molecules/graph';
import { GraphData } from '../../../types/interface/graphData';

interface ProjectItemProps {
	graphData: GraphData | null;
	loading: boolean;
}

export const ProjectItem = ({ graphData, loading }: ProjectItemProps) => {

	return (
	<>
		<div className={s.projectItem}>
			<div className={s.statInfo}>
				<ProjectStatistic graphData={graphData} loading={loading} />
				<UploadExport />
			</div>
			<ProjectTable graphData={graphData} loading={loading} />
		</div>
		<div className={s.projectItem}>
			<Graph graphData={graphData} loading={loading} />
		</div>
	</>
)};

import s from './upload-button.module.scss';
import { SvgIcon } from '../../atoms/svg-icon';
import Button from 'antd/es/button';
import { letUpload } from '../../../services/common/upload';
import { useEffect, useRef, useState } from 'react';
import { SentFile } from '../../../services/common/sent-file';

export const UploadButton = () => {
	const [sent, setSent] = useState<boolean>(false);
	const upload = useRef<HTMLInputElement | null>();
	useEffect(() => {
		SentFile(sent, upload, setSent);
	}, [sent]);
	return (
		<>
			<input ref={(el) => (upload.current = el)} className={s.fileUploadHidden} type="file" />
			<Button className={s.uploadButton} onClick={() => letUpload(upload, setSent)}>
				<SvgIcon icon="uploadButton" />
				<span className={s.uploadButtonText}>Upload</span>
			</Button>
		</>
	);
};

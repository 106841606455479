import Profile from './profile';
import Projects from './projects';
// import Project from './project';
import Auth from './auth';
import ApiKeys from './api-keys';
import Team from './team';

interface RoutesProps {
	path: string;
	exact: boolean;
	component: any;
	name: string;
	mainRoutes: boolean;
	authRoutes: boolean;
	status: boolean;
}

export const routes: RoutesProps[] = [
	{
		path: '/profile',
		exact: true,
		component: Profile,
		name: 'Profile',
		mainRoutes: false,
		authRoutes: false,
		status: true,
	},
	{
		path: '/projects',
		exact: true,
		component: Projects,
		name: 'Projects',
		mainRoutes: false,
		authRoutes: false,
		status: true,
	},
	{
		path: '/auth',
		exact: true,
		component: Auth,
		name: 'Auth',
		mainRoutes: true,
		authRoutes: true,
		status: true,
	},
	{
		path: '/api-keys',
		exact: true,
		component: ApiKeys,
		name: 'Api keys',
		mainRoutes: false,
		authRoutes: false,
		status: true,
	},
	{
		path: '/team',
		exact: true,
		component: Team,
		name: 'Team',
		mainRoutes: false,
		authRoutes: false,
		status: true,
	},
	{
		path: '/verify/:token',
		exact: true,
		component: Auth,
		name: 'Auth',
		mainRoutes: false,
		authRoutes: true,
		status: false,
	},
	{
		path: '/',
		exact: true,
		component: Auth,
		name: 'Auth',
		mainRoutes: true,
		authRoutes: false,
		status: false,
	},
];

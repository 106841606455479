import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
interface PortalProps {
	children: React.ReactNode;
}
export const Portal = ({ children }: PortalProps) => {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
		return () => setMounted(false);
	}, []);

	const target = (typeof window !== 'undefined' &&
		document?.querySelector('#portal')) as HTMLDivElement;

	return mounted ? createPortal(children, target) : null;
};

import { GraphData } from '../../types/interface/graphData';

interface RequestProps {
	route: string;
	method?: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'OPTIONS';
	body?: { [key: string]: string | number | undefined | boolean | Record<string, unknown> };
}

type ErrorParams = {
	status: number;
	body?: unknown;
	message: string;
}
export class RequestError extends Error {
	readonly status: number;
	readonly body?: unknown;

	constructor(params: ErrorParams) {
		super(params.message);

		this.body = params.body;
		this.status = params.status;
	}
}

export type RequestData<T> = {
	data: T;
	message?: string;
	status: number;
}

export async function request<T = unknown>(params: RequestProps): Promise<RequestData<T>> {
	const { route, method = 'GET', body } = params;
	const response = await fetch(`${process.env.REACT_APP_HOST}${route}`, {
		method: method,
		headers: {
			'Content-Type': 'application/json',
		},
		body: body ? JSON.stringify(body) : undefined,
		credentials: 'include',
	});

	if (!response.ok) {
		let body: Record<string, unknown> = {};
		try {
			body = await response.json();
		} catch (e) {
			// ignored
		}

		throw new RequestError({
			body,
			message: `[${method}] ${response.url}: ${response.statusText}`,
			status: response.status,
		});
	}

	const data = await response.json();

	return { data, message: data.message, status: response.status } as RequestData<T>;
}

export const requests = {
	createIntegration: async (projectId: string, name: string,  provider: string, data: Record<string, unknown>) =>
		request({
			route: `/api/projects/${projectId}/integrations`,
			method: 'POST',
			body: { provider, name, data },
		}),
	updateIntegration: async (projectId: string, integrationId: string, name: string, data: Record<string, unknown>) =>
		request({
			route: `/api/projects/${projectId}/integrations/${integrationId}`,
			method: 'PUT',
			body: { name, data: data },
		}),
	deleteIntegration: async (projectId: string, integrationId: string) =>
		request({ route: `/api/projects/${projectId}/integrations/${integrationId}`, method: 'DELETE' }),
	createWebhook: async (projectId: string, url: string) =>
		request({
			route: `/api/projects/${projectId}/webhooks`,
			method: 'POST',
			body: { url },
		}),
	deleteWebhook: async (projectId: string) =>
		request({
			route: `/api/projects/${projectId}/webhooks`,
			method: 'DELETE',
		}),
	signIn: async (email: string, password: string, remember: boolean = false) =>
		request<{ id: string; name: string; email: string; accountId: number }>({
			route: '/auth',
			method: 'POST',
			body: { email, password, remember },
		}),
	signUp: async (email: string, reset?: boolean) =>
		request({
			route: '/api/accounts',
			method: 'POST',
			body: { email, reset },
		}),
	logout: async () => request({ route: '/auth/logout' }),
	enterPassword: async (enterToken: string, password: string, idToken?: string) =>
		request({
			route: '/api/accounts/enter',
			method: 'POST',
			body: { enterToken, password, idToken, provider: idToken ? 'google' : 'cabinet' },
		}),
	getTeam: async () => request({ route: `/api/users` }),
	getProfile: async () => request({ route: '/api/users/me' }),
	updateProfile: async ({ name }: { name: string }) => request({ route: '/api/users/me', method: 'PUT', body: { name } }),
	getApiList: async () => request({ route: '/api/accounts' }),
	inviteUser: async (email: string) => request({
		method: 'POST',
		route: `/api/users/invite`,
		body: { email }
	}),
	removeMember: (id: string) => request({
		method: 'DELETE',
		route: `/api/users/${id}`,
	}),
	getProjects: async (order: string, filtered: string) => request({ route: `/api/projects?sorted=${order}&filtered=${filtered}` }),
	getProject: async (id: string) => request({ route: `/api/projects/${id}` }),
	setToken: async (id: string) => request({ route: `/api/projects/${id}/setToken` }),
	dropToken: async (id: string) => request({ route: `/api/projects/${id}/dropToken` }),
	createProject: async ({ name, average }: { name: string, average: number }) =>
		request({
			route: '/api/projects',
			method: 'POST',
			body: { name, average },
		}),
	updateProject: async (id: string, { name, average }: { name: string, average: number }) =>
		request({
			route: `/api/projects/${id}`,
			method: 'PUT',
			body: { name, average },
		}),
	deleteProject: async (id: string) => request({ route: `/api/projects/${id}`, method: 'DELETE' }),
	getPlans: async () => request({ route: '/api/plans' }),
	getGraph: async (
		type: string,
		graphId: string,
		cacheForceUpdate: boolean,
		noCacheLoad: boolean,
		graphFilterParams: string,
	) =>
		request<GraphData>({
			route: `/api/projects/${graphId}?type=${type}&cacheForceUpdate=${cacheForceUpdate}&noCacheLoad=${noCacheLoad}&filterAttrArr=${graphFilterParams}`,
		}),
};

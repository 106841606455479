import { Portal } from '../../../services/common/portal';
import { usePopper } from 'react-popper';
import { useState } from 'react';

interface InfoModalProps {
	setIsModalOpen: (value: boolean) => void;
	referenceElement: HTMLDivElement | null;
	children: React.ReactNode;
}

export const Modal = ({ setIsModalOpen, referenceElement, children }: InfoModalProps) => {
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
	const { attributes, styles } = usePopper(referenceElement, popperElement, {});

	return (
		<Portal>
			<div className="modal">
				<button onClick={() => setIsModalOpen(false)} className="clickOutSide dark" />
				<div
					className="ant-modal-full"
					style={styles.popper}
					{...attributes.popper}
					ref={setPopperElement}
				>
					<div className="ant-modal-content" style={{padding: "20px 20px 90px 20px"}}>
						{children}
					</div>
				</div>
			</div>
		</Portal>
	);
};

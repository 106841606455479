import { getConnectiorString } from '../../../services/common/clickstream-code';
// import { getGoogleConnectorStr } from '../../../services/common/google-code';
import { IProjectsModel } from '../../../services/stores/projects';

export type TabData = {
	key: string;
	title: string;
	text1: string;
	text2?: string;
	text3?: string;
	codeBlock?: string;
	video?: string;
	upload?: boolean;
}

export const tabs = (project: IProjectsModel): TabData[] => {
	return [
		{
			key: '1',
			title: 'Insightarc connector',
			text1:
				'To install the <b>Insightarc analytics</b> to your site, copy the following code and paste ' +
				'it immediately after the <b>&lt;head&gt;</b> tag on every page of your site. ' +
				'You need only one global snippet per page.',
			text2:
				'This snippet loads the iarc.js library establishes API_KEY and connects Insightarc analytics to your site.',
			text3: 'Watch a tutorial video showing how to work with Insightarc connector.',
			codeBlock: getConnectiorString(project),
			video: 'Jaen1lZlvv4',
		},
		// {
		// 	title: 'Google analytics connector',
		// 	text1:
		// 		'To install the <b>Insightarc Google analytics connector</b> tag, copy the following code and paste it immediately after the &lt;head/&gt; tag on every page of your site. Replace API_KEY with the API key of the project to which you want to send data. You need only one global snippet per page.',
		// 	text2:
		// 		'This snippet loads the iarc.js library establishes API_KEY and connects Google analytics to Insightarc. The API Key is generated in the Project settings, which are hidden by three dots.',
		// 	codeBlock: getGoogleConnectorStr(project),
		// 	text3: 'Watch a tutorial video showing how to work with Google analytics connector.',
		// 	video: 'Jaen1lZlvv4',
		// },
		{
			key: '2',
			title: 'Upload analytics data',
			text1:
				'Upload a <b>file with analytics data</b> to start your new project. CSV or XLS files required. Download at <a download href="../../../assets/mock/example.xlsx">example file here.</a>',
			upload: true,
		},
	];
}

import { useCallback } from 'react';
import s from './social-button.module.scss';
import SocialButton from '../../atoms/social-button';
import { SvgIcon } from '../../atoms/svg-icon';
import { useStore } from '../../../services/stores/use-store';
import notification from 'antd/es/notification';
export const SocialButtons = () => {
  const { authStore } = useStore();
  const { letEnterPassword, setConfirmationText } = authStore;

  const handleSocialLogin = useCallback((user: any) => {
    const { token } = user;
    letEnterPassword('', '', token.idToken)
      .then(({ status, message }) => {
        if (status === 200) {
          setConfirmationText('Your InsightArc account is ready!');
          window.location.href = '/projects';
          return;
        }

        notification.error({
          message: 'Error',
          description: `Something went wrong: ${message}`,
        });
      })
      .catch((e) => {
        notification.error({
          message: 'Error',
          description: `Something went wrong: ${e.mesage ? e.mesage : e}`,
        });
      });
  }, []);

  const onError = useCallback((result) => {
    console.log('Auth failed:', result);
  }, []);

  return (
    <>
      <span className={s.quickSign}>Quick Sign in:</span>
      <SocialButton
        provider="google"
        appId="845946379142-mj2j1plmb6ifd2o5a4d76ikn7jdaq771.apps.googleusercontent.com"
        onLoginSuccess={handleSocialLogin}
        onLoginFailure={onError}
      >
        <SvgIcon icon="google"/>
      </SocialButton>
    </>
  );
};

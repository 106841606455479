const youtubeApi = 'https://www.youtube.com/iframe_api';
export const addYouTubeScript = () => {
	try {
		const scriptTag = document.createElement('script');
		scriptTag.src = youtubeApi;
		const firstScriptTag = document.getElementsByTagName('script')[0];
		if (firstScriptTag) {
			firstScriptTag?.parentNode?.insertBefore(scriptTag, firstScriptTag);
		}
	} catch (er) {
		console.log(er);
	}
	return true;
};

export const youTubeCode = (activeVideo: string, width: number, height: number) => {
	// @ts-ignore
	new window.YT.Player(`player`, {
		height: height,
		width: width,
		videoId: activeVideo,
		allowFullScreen: true,
		rel: 0,
		controls: 0,
		showinfo: 0,
		events: {
			onReady: (event: {
				target: {
					playVideo: () => void;
					data: null;
				};
			}) => {
				event.target?.playVideo();
			},
		},
	});
};

export const removeYoutubeScript = () => {
	const scriptTags = document.querySelectorAll('script');
	scriptTags.forEach((el) => {
		if (el.src === youtubeApi) {
			el.remove();
		}
	});
	const YoutubeScriptTag = document.getElementById('www-widgetapi-script');
	if (YoutubeScriptTag) {
		YoutubeScriptTag?.remove();
	}
};

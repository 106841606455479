import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import Menu from 'antd/es/menu';
import Button from 'antd/es/button';
import UserOutlined from '@ant-design/icons/UserOutlined'

import { useStore } from '../../../services/stores/use-store';
import { CustomButton } from '../../atoms/custom-button';
import s from '../../organisms/header/header.module.scss';
import { Portal } from '../../../services/common/portal';

import { observer } from 'mobx-react-lite';
import { withRouter } from '../../../services/hooks/withRouter';

const menuItems = [
	{ id: 1, title: 'Profile', path: '/profile' },
	{ id: 2, title: 'Team', path: '/team' },
	/*{ id: 3, title: 'Api keys', path: '/api-keys' },*/
	{ id: 3, title: 'Logout', path: '/auth' },
];

const TopMenu = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const {
		authStore: { letLogout },
		projects: { setIsActiveProject },
	} = useStore();

	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
	const { attributes, styles } = usePopper(referenceElement, popperElement, {});
	const selectMenuItem = (title: string, path: string) => {
		if (title === 'Logout') {
			letLogout();
		}
		navigate(path);
		setIsMenuOpen(false);
		setIsActiveProject(null);
	};

	return (
		<div ref={setReferenceElement} className={s.headerMenu}>
			<Button
				shape="circle"
				type="primary"
				icon={<UserOutlined />}
				onClick={() => setIsMenuOpen(true)}
			/>
			{isMenuOpen && (
				<Portal>
					<button onClick={() => setIsMenuOpen(false)} className="clickOutSide" />
					<div
						style={styles.popper}
						{...attributes.popper}
						className={s.menu}
						ref={setPopperElement}
					>
						<Menu>
							{menuItems.map(({ title, path }) => (
								<Menu.Item
									title={title}
									key={title}
									disabled={pathname === path}
									className={pathname === path ? s.isDisabled : ''}
								>
									<CustomButton
										text={title}
										className={s.menuButton}
										type="text"
										onClick={() => selectMenuItem(title, path)}
									/>
								</Menu.Item>
							))}
						</Menu>
					</div>
				</Portal>
			)}
		</div>
	);
};

export default withRouter(observer(TopMenu));

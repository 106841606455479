import s from './projects.module.scss';
import { StatisticItems } from '../../molecules/statistic-items';
import { StatisticInfo } from '../../atoms/statistic-info';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../services/stores/use-store';
import { withRouter } from '../../../services/hooks/withRouter';

function ProjectsItem() {
  const {
    projects: { getProjectsStat },
  } = useStore();

  return (
    <div className={s.panels}>
      {getProjectsStat?.map(
        (project) => (
          <div className={s.panel} key={project.id}>
            <Link className={s.link} to={`/project/${project.id}`}>
              <div className={s.stats}>
                <div className={s.statsInfo}>
                  <StatisticItems
                    nodes={isDemoProject(project.id) ? 44016 : project.nodes ?? 0}
                    cases={isDemoProject(project.id) ? 27 : project.cases ?? 0}
                    bottleNecks={isDemoProject(project.id) ? 1 : project.bottleNecks ?? 0}
                    problems={isDemoProject(project.id) ? 19 : project.problems ?? 0}
                  />
                </div>
              </div>
            </Link>
            <StatisticInfo project={project} />
          </div>
        ),
      )}
    </div>
  );
}

function isDemoProject(id: string) {
  return id === '00000000-0000-0000-0000-000000000023';
}

export default withRouter(observer(ProjectsItem));

import Spin from 'antd/es/spin';
import cn from 'classnames';

import { SvgIcon } from '../svg-icon';

import css from './spinner.module.scss';

interface SpinnerProps {
	className?: string;
}
export const Spinner = ({ className }: SpinnerProps) => (
	<div className={cn(css.spinner, className)}>
		<Spin className="anticon-spin" indicator={<SvgIcon icon="spinner" />} />
	</div>
);

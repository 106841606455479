import { useEffect, useState } from 'react';
import Spin from 'antd/es/spin';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { ActionPanel } from '../../compoments/organisms/action-panel';
import ProjectsItem from '../../compoments/organisms/projects-item';
import { ProjectsManual } from '../../compoments/molecules/projects-manual';
import { useStore } from '../../services/stores/use-store';
import { withRouter } from '../../services/hooks/withRouter';
import css from './projects.module.scss';

const Projects = () => {
  const { projects } = useStore();

  useEffect(() => {
    projects.setIsActiveProject(null);
  }, []);

  const [update, setUpdate] = useState<boolean>(true);

  useEffect(() => {
    if (update) {
      projects.getProjects();
      setUpdate(false);
    }
  }, [update]);

  if (projects.isProjectsLoading) {
    return (
      <div className={cn(css.dashboard, css.dashboard__full)}>
        <div className={css.loader}>
          <Spin size="large"/>
        </div>
      </div>
    );
  }

  return (
    <>
      <ActionPanel
        setUpdate={setUpdate}
        setFilteredBy={projects.setFilteredBy}
        buttonTitle="New"
        selectedValue={projects.getSortBy}
        useSearch
        setSelectedSort={projects.setSortBy}
        dropdownSelector
        withInfo
        infoComponent={<ProjectsManual />}
      />
      <div className={cn('pageContent', css.dashboard)}>
        {projects.getProjectsLength === 0 ? <ProjectsManual /> : <ProjectsItem />}
      </div>
    </>
  );
};

export default withRouter(observer(Projects));

import * as React from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import { IStore } from './store';

export const StoreContext = React.createContext<IStore | null>(null);

interface IProvider {
	children: React.ReactNode;
	store: IStore;
}

const StoreProvider = ({ children, store }: IProvider): JSX.Element => {
	const value = useLocalObservable(() => store);

	return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

export default StoreProvider;

import s from './notification-alert.module.scss';

interface NotificationAlertProps {
	text: string;
}
export const NotificationAlert = ({ text }: NotificationAlertProps) => (
	<div className={s.notificationAlert}>
		<p className={s.text}>{text}</p>
	</div>
);

import { ReactComponent as LightLogo } from '../../../assets/images/light-logo.svg';
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
import { ReactComponent as HomeIcon } from '../../../assets/images/home-icon.svg';
import { ReactComponent as EventsIcon } from '../../../assets/images/events-icon.svg';
import { ReactComponent as ErrorsIcon } from '../../../assets/images/errors-icon.svg';
import { ReactComponent as ProblemsIcon } from '../../../assets/images/problems-icon.svg';
import { ReactComponent as UniqueIcon } from '../../../assets/images/unique-icon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import { ReactComponent as SpinnerIcon } from '../../../assets/images/spinner.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/copy.svg';
import { ReactComponent as UploadButton } from '../../../assets/images/uploadButton.svg';
import { ReactComponent as ShowVideo1 } from '../../../assets/images/showVideo1.svg';
import { ReactComponent as ShowVideo2 } from '../../../assets/images/showVideo2.svg';
import { ReactComponent as CheckOne } from '../../../assets/images/check-one.svg';
import { ReactComponent as Down } from '../../../assets/images/down.svg';
import { ReactComponent as Question } from '../../../assets/images/question.svg';
import {
	UserOutlined,
	LockOutlined,
	MailOutlined,
	GoogleOutlined,
	MoreOutlined,
	CloseOutlined,
	UploadOutlined,
	ExportOutlined,
	FullscreenOutlined,
	FilterOutlined,
	InfoOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';

interface SvgIconProps {
	icon?: string;
	fill?: string;
	className?: string;
}

export const SvgIcon = ({ icon, fill }: SvgIconProps) => {
	switch (icon) {
		case 'username':
			return <UserOutlined />;
		case 'password':
		case 'newPassword':
		case 'repeatPassword':
			return <LockOutlined />;
		case 'e-mail':
			return <MailOutlined />;
		case 'lightLogo':
			return <LightLogo />;
		case 'logo':
			return <Logo />;
		case 'google':
			return <GoogleOutlined style={{ fontSize: '24px' }} />;
		case 'home':
			return <HomeIcon />;
		case 'plus':
			return <PlusIcon fill={fill} />;
		case 'events':
		case 'findOperationLeaks':
			return <EventsIcon />;
		case 'errors':
			return <ErrorsIcon />;
		case 'problems':
			return <ProblemsIcon />;
		case 'unique':
			return <UniqueIcon />;
		case 'more':
			return <MoreOutlined />;
		case 'close':
			return <CloseOutlined />;
		case 'spinner':
			return <SpinnerIcon />;
		case 'upload':
			return <UploadOutlined />;
		case 'export':
			return <ExportOutlined />;
		case 'fullscreen':
			return <FullscreenOutlined />;
		case 'filter':
			return <FilterOutlined />;
		case 'info':
			return <InfoOutlined />;
		case 'copy':
			return <CopyIcon />;
		case 'uploadButton':
			return <UploadButton />;
		case 'showVideo1':
			return <ShowVideo1 />;
		case 'showVideo2':
			return <ShowVideo2 />;
		case 'checkOne':
			return <CheckOne />;
		case 'down':
			return <Down />;
		case 'question':
			return <Question />;
		case 'infoCircle':
			return <InfoCircleOutlined />;
		default:
			return <></>;
	}
};

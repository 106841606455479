import s from './auth.module.scss';
import AuthForm from '../../compoments/organisms/auth-form';
import { observer } from 'mobx-react-lite';
import { withRouter } from '../../services/hooks/withRouter';

const Auth = () => (
	<div className={s.auth}>
		<AuthForm />
	</div>
);

export default withRouter(observer(Auth));

import { CustomInput } from '../../atoms/custom-input';
import styles from '../../organisms/auth-form/form-items.module.scss';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../atoms/custom-button';
import { SocialBlock } from '../social-block';
import RememberForgot from '../remember-forgot';
import { useStore } from '../../../services/stores/use-store';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withRouter } from '../../../services/hooks/withRouter';

export const SignInForm = () => {
	const { authStore } = useStore();
	const navigate = useNavigate();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [sendForm, setSendForm] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
	const [errorEmail, setErrorEmail] = useState<boolean>(false);
	const [statusEmailMessage, setStatusEmailMessage] = useState<string>('');
	const [errorPassword, setErrorPassword] = useState<boolean>(false);
	const [statusPasswordMessage, setStatusPasswordMessage] = useState<string>('');

	useEffect(() => {
		if (sendForm) {
			authStore.letSignIn({
				email,
				password,
				remember: authStore.isRemember,
			}).then(({ status, message }) => {
				if (status && message) {
					if (status === 200) {
						navigate('/projects');
						setSendForm(false);
					} else if (status === 401) {
						setErrorPassword(true);
						setStatusPasswordMessage(message);
					} else if (status === 500) {
						setErrorEmail(true);
						setStatusEmailMessage(message);
					}
				}
			});
		}
	}, [sendForm]);

	useEffect(() => {
		let mounted = true;
		let changeErrorTimeOut: NodeJS.Timeout;
		if (errorEmail || errorPassword) {
			const clearError = function clearStatus() {
				if (!mounted) {
					return;
				}
				setErrorEmail(false);
				setStatusEmailMessage('');
				setErrorPassword(false);
				setStatusPasswordMessage('');
				setPassword('');
				setSendForm(false);
				setIsDisabled(false);
				setIsDisabledButton(true);
				clearTimeout(changeErrorTimeOut);
			};

			changeErrorTimeOut = setTimeout(clearError, 2500);
		}
		return () => {
			mounted = false;
		}
	}, [errorEmail, errorPassword]);

	useEffect(() => {
		if (password.length > 0 && email.length > 0) {
			setIsDisabledButton(false);
		} else {
			setIsDisabledButton(true);
		}
	}, [password, email]);

	return (
		<form>
			<CustomInput
				labelClassName={styles.formItem}
				onChangeString={setEmail}
				type="email"
				placeholder="email"
				name="e-mail"
				withIcon
				required
				value={email}
				isDisabled={isDisabled}
				error={errorEmail}
				errorMessage={statusEmailMessage.length > 0 ? statusEmailMessage : undefined}
			/>
			<CustomInput
				labelClassName={styles.formItem}
				onChangeString={setPassword}
				type="password"
				placeholder="password"
				name="password"
				withIcon
				required
				value={password}
				isDisabled={isDisabled}
				error={errorPassword}
				errorMessage={statusPasswordMessage.length > 0 ? statusPasswordMessage : undefined}
			/>
			<RememberForgot />
			<CustomButton
				isDisabled={isDisabledButton}
				type="text"
				className={styles.signInButton}
				text="Sign In"
				onClick={() => {
					setSendForm(true);
					setIsDisabled(true);
				}}
			/>
			<SocialBlock setFormType={authStore.setAuthState} formType="Sign Up" />
		</form>
	);
};

export default withRouter(observer(SignInForm));

import s from './header.module.scss';
import { SvgIcon } from '../../atoms/svg-icon';
import TopMenus from '../../molecules/top-menu';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import HeaderProjectItem from '../../molecules/header-project-item';

export type HeaderProps = {
  className?: string;
}

const Header = ({ className }: HeaderProps) => {
  return (
    <header className={className}>
      <div className={s.header}>
        <div className={s.leftBock}>
          <Link to={'/projects'}>
            <div className={s.logo}>
              <SvgIcon icon="lightLogo"/>
            </div>
          </Link>
        </div>
        <div className={s.rightBlock}>
          <HeaderProjectItem/>
          <div className={s.question}>
            <a href="https://www.insightarc.com/blog" target="_blank">
              <SvgIcon icon="infoCircle" fill="#FFFFFF"/>
            </a>
          </div>
          <TopMenus/>
        </div>
      </div>
    </header>
  );
};

export default observer(Header);

import s from './video-button.module.scss';
import Button from 'antd/es/button';
import { SvgIcon } from '../../atoms/svg-icon';

interface VideoButtonProps {
	id?: string;
	onClick: () => void;
}

export const VideoButton = ({ onClick, id }: VideoButtonProps) => (
	<Button className={s.videoButton} onClick={onClick}>
		<SvgIcon icon={`showVideo${id}`} />
	</Button>
);

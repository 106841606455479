export const columns = [
	{
		title: 'Key',
		dataIndex: 'apiKey',
		key: 'apiKey',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		width: 128,
		key: 'status',
	},
];

export const data = [
	{
		key: 1,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
	{
		key: 2,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
	{
		key: 13,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
	{
		key: 4,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
	{
		key: 5,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
	{
		key: 6,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
	{
		key: 7,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
	{
		key: 8,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
	{
		key: 9,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
	{
		key: 10,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
	{
		key: 11,
		apiKey: '5:50b7c17e31812fc9b5c85ab1e2d6dc543081c715642bc6eb2b5838aff697',
		status: 'Active',
	},
];

import Checkbox from 'antd/es/checkbox';

import { CustomButton } from '../../atoms/custom-button';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../services/stores/use-store';
import { withRouter } from '../../../services/hooks/withRouter';

import css from './remember-forgot.module.scss';

const RememberForgot = () => {
	const {
		authStore: { setAuthState, isRemember, setIsRemember },
	} = useStore();

	return (
		<div className={css.rememberBlock}>
			<Checkbox
				className={css.checkbox}
				checked={isRemember}
				onChange={() => setIsRemember(!isRemember)}
			>
				Remember me
			</Checkbox>
			<CustomButton
				onClick={() => setAuthState('forgottenPassword')}
				className={css.forgot}
				type="link"
				text="Forgot your password?"
			/>
		</div>
	);
};

export default withRouter(observer(RememberForgot));

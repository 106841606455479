import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';

import { IProjectsModel } from '../../../../../../services/stores/projects';

import { useStore } from '../../../../../../services/stores/use-store';
import css from './ProjectDangerZone.module.scss';

export type ProjectDangerZoneProps = {
  project: IProjectsModel;
};

export function ProjectDangerZone({ project }: ProjectDangerZoneProps) {
  const { projects } = useStore();
  const navigate = useNavigate();

  const onRemoveApiKey = useCallback(async () => {
    Modal.confirm({
      title: `Are you sure you want to remove API token for "${project.name}"?`,
      content: 'This action can not be undone.',
      icon: <ExclamationCircleOutlined color="red"/>,
      okType: 'danger',
      onOk: async () => {
        projects.closeProject(project.id);
        await projects.dropToken(project.id);
        await projects.getProjects();
      },
      onCancel: () => {
      },
    });
  }, [project]);

  const onDeleteClick = useCallback(async () => {
    Modal.confirm({
      title: `Are you sure you want to delete "${project.name}"?`,
      content: 'This action can not be undone.',
      icon: <ExclamationCircleOutlined color="red"/>,
      okType: 'danger',
      onOk: async () => {
        projects.closeProject(project.id);
        await projects.deleteProject(project.id);
        await projects.getProjects();
        await navigate('/projects');
      },
      onCancel: () => {
      },
    });
  }, [project]);

  return (
    <div className={css.wrapper}>
      <Button
        danger
        icon={<DeleteOutlined />}
        onClick={onRemoveApiKey}
      >Remove API key</Button>
      <Button
        danger
        icon={<DeleteOutlined />}
        type="primary"
        onClick={onDeleteClick}
      >Delete project</Button>
    </div>
  );
}

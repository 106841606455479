import { useCallback, useMemo, useRef, useState } from 'react';

import Modal from 'antd/es/modal';
import Tabs, { TabsProps } from 'antd/es/tabs';

import { useStore } from '../../../../../../services/stores/use-store';
import { IProjectsModel } from '../../../../../../services/stores/projects';

import { ProjectInfoForm } from './ProjectInfoForm';
import { ProjectWebhookForm } from './ProjectWebhookForm';
import { ProjectDangerZone } from './ProjectDangerZone';
import { ProjectIntegrations } from './ProjectIntegrations';

export type ProjectSettingsProps = {
  project: IProjectsModel;
  open: boolean;
  onClose?(): void;
};

type FormState = {
  name?: string;
  ticketSize?: number;
}

export function ProjectSettings({ project, open, onClose }: ProjectSettingsProps) {
  const [isBusy, setIsBusy] = useState(false);
  const { projects } = useStore();
  const formState = useRef<FormState>({});

  const onProjectChange = useCallback((data) => {
    formState.current = data;
  }, []);

  const items = useMemo<TabsProps['items']>(() => {
    return [
      {
        key: 'Project info',
        label: 'Project info',
        children: <ProjectInfoForm project={project} onChange={onProjectChange} />,
      },
      {
        key: 'Integrations',
        label: 'Integrations',
        children: <ProjectIntegrations project={project} />,
      },
      {
        key: 'Webhook',
        label: 'Webhook',
        children: <ProjectWebhookForm project={project} />,
      },
      {
        key: 'Danger zone',
        label: 'Danger zone',
        children: <ProjectDangerZone project={project} />,
      }
    ];
  }, [project]);

  const onSaveClick = useCallback(async () => {
    setIsBusy(true);

    try {
      await projects.updateProject(project.id, {
        name: formState.current?.name?.trim() ?? project.name,
        average: formState.current?.ticketSize ?? project.average,
      });

      onClose?.();
    } catch (e) {
      console.error('Failed to update project: ', e.message);
      setIsBusy(false);
    }

  }, [onClose]);

  return (
    <Modal
      title="Settings"
      open={open}
      onOk={onSaveClick}
      okText="Save"
      maskClosable={false}
      onCancel={onClose}
      confirmLoading={isBusy}
      width="50%"
    >
      <Tabs
        items={items}
        tabPosition="left"
      />
    </Modal>
  );
}

import React from 'react';
import SocialLogin from 'react-social-login';

class SocialButton extends React.Component {
	render() {
		// @ts-ignore
		const { children, triggerLogin, ...props } = this.props;
		return (
			<button style={{ background: 'none', border: 'none' }} onClick={triggerLogin} {...props}>
				{children}
			</button>
		);
	}
}
export default SocialLogin(SocialButton);

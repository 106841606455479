import clickstream from '../../../assets/images/clickstream.png';
import uploadFile from '../../../assets/images/upload-file.png';
import { CustomTab } from '../../atoms/custom-tab';
import s from './custom-tabs.module.scss';
import { CustomTabsExtraContent } from '../../atoms/custom-tabs-extra-content';

const tabs = [
	{ id: '1', title: 'Insightarc connector', icon: clickstream },
	{ id: '2', title: 'Upload analytics data', icon: uploadFile },
];

interface CustomTabProps {
	setActiveKey: (value: string) => void;
	activeKey: string;
}

export const CustomTabs = ({ setActiveKey, activeKey }: CustomTabProps) => (
	<div className={s.customTabs}>
		<CustomTabsExtraContent />
		{tabs.map(({ id, title, icon }) => (
			<CustomTab
				key={id}
				setActiveKey={setActiveKey}
				id={id}
				title={title}
				icon={icon}
				activeKey={activeKey}
			/>
		))}
	</div>
);

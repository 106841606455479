import { CustomButton } from '../custom-button';

interface FullscreenButtonProps {
	graphRef: React.MutableRefObject<HTMLInputElement>;
	onClick: () => void;
}

export const FullscreenButton = ({ graphRef }: FullscreenButtonProps) => {
	const toggleFullScreen = () => {

		// setUserParams({...userParams, fullsceen: true})
		// setNewData({...newData, options: {...newData.options, height: '100%'}})
		const elem = graphRef.current;
		// onClick();

		if ((document.fullscreenElement !== undefined && document.fullscreenElement === null)) {
		  if (Boolean(elem.requestFullscreen)) {
			elem.requestFullscreen();
		  }
		} else {
		  if (Boolean(document.exitFullscreen())) {
			document.exitFullscreen();
		  }
		}
	  }
	return (
		<div>
			<CustomButton
				withIcon
				icon="fullscreen"
				onClick={() => toggleFullScreen()}
			/>
		</div>
	);
};

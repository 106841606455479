import { UserOutlined } from '@ant-design/icons';
import Avatar from 'antd/es/avatar';

interface UserAvatarProps {
	email: string;
	size: number;
}

export const UserAvatar = ({ email, size }: UserAvatarProps) => {
	return (
			<Avatar
				size={size}
				icon={<UserOutlined />}
			>
				{email.substring(0, 1).toUpperCase()}
			</Avatar>
	);
};

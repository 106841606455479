import { types as t, cast, Instance, getEnv } from 'mobx-state-tree';
import { toJS } from 'mobx';

import { routes } from '../../pages/routes';
import { getFromLocalStorage } from '../common/get-from-local-storage';

import AuthStore from './auth';
import TeamStore from './team';
import ApiKeys from './api-keys';
import ProjectsStore from './projects';

const Store = t
	.model('Store', {
		appLoaded: false,
		mainRoutes: t.array(t.string),
		authRoutes: t.array(t.string),
		authStore: t.optional(AuthStore, {
			isAuthenticated: false,
			isAuthLoading: true,
			isAlone: false,
			isRemember: false,
			authState: 'Sign In',
			enterToken: '',
			confirmationText: '',
		}),
		teamStore: t.optional(TeamStore, {
			isTeamLoading: false,
			membersList: [],
		}),
		apiKeyStore: t.optional(ApiKeys, {
			isApiKeysLoading: false,
			apiKeysList: [],
		}),
		projects: t.optional(ProjectsStore, {
			isProjectsLoading: false,
			activeProjectId: null,
			projects: [],
			lastOpened: getFromLocalStorage<string[]>('lastOpened') ?? [],
			sortBy: 'Recent',
			filteredBy: '',
		}),
	})
	.views((self) => ({
		get api() {
			return getEnv(self).api;
		},
		get auth() {
			return getEnv(self).auth;
		},
		get qs() {
			return getEnv(self).qs;
		},
		get getMainRoutes() {
			return toJS(self.mainRoutes);
		},
		get getAuthRoutes() {
			return toJS(self.authRoutes);
		},
	}))
	.actions((self) => {
		const afterCreate = () => {
			setAppLoaded(false);
			setMainRoutes();
			setAuthRoutes();
			setAppLoaded(true);
		};

		const setAppLoaded = (status: boolean) => {
			self.appLoaded = status;
		};
		const setMainRoutes = () => {
			self.mainRoutes = cast(
				routes.filter(({ mainRoutes }) => Boolean(mainRoutes)).map(({ path }) => path)
			);
		};
		const setAuthRoutes = () => {
			self.authRoutes = cast(
				routes.filter(({ authRoutes }) => Boolean(authRoutes)).map(({ path }) => path)
			);
		};

		return {
			setMainRoutes,
			setAuthRoutes,
			afterCreate,
			setAppLoaded,
		};
	});

export type IStore = Instance<typeof Store>;

export default Store;

import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import MailOutlined from '@ant-design/icons/MailOutlined';
import notification from 'antd/es/notification';
import Form from 'antd/es/form';

import { useStore } from '../../services/stores/use-store';
import TeamList from '../../compoments/organisms/team-list';
import { withRouter } from '../../services/hooks/withRouter';
import s from './team.module.scss';


const Team = () => {
	const { teamStore } = useStore();
	const [update, setUpdate] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isBusy, setIsBusy] = useState(false);
	const [form] = Form.useForm();


	useEffect(() => {
		teamStore.getTeamList();
	}, []);

	useEffect(() => {
		if (update) {
			teamStore.getTeamList();
			setUpdate(false);
		}
	}, [update]);

	const sendInvite = useCallback(async (values: { email: string }) => {
		const { email } = values;

		setIsBusy(true);

		try {
			await teamStore.sendInvite(email);
			notification.success({
				message: 'Success',
				description: `Invitation for user ${email} sent.`,
			});
			form.resetFields()
			setIsModalOpen(false);
		} catch (e) {
			const err = e as Error;
			notification.error({
				message: 'Error',
				description: `Can not invite user: ${err.message}`,
			});
		} finally {
			setIsBusy(false);
		}
	}, [form]);

	const onOk = useCallback(() => {
		form
			.validateFields()
			.then((values) => sendInvite(values))
			.catch()
	}, []);

	const onAdd = useCallback(() => setIsModalOpen(true), []);

	return (
		<>
			<div className={cn(s.team)}>
				<TeamList onAdd={onAdd} />
				<Modal
					title="Invite team members"
					centered
					open={isModalOpen}
					onOk={onOk}
					confirmLoading={isBusy}
					onCancel={() => {
						setIsModalOpen(false);
						form.resetFields();
					}}
				>
					<Form form={form} layout="vertical" name="invite" autoComplete="off" initialValues={{ email: '' }}>
						<Form.Item
							name="email"
							label="Email:"
							rules={[{ required: true, message: 'Please input user email' }, { type: 'email', message: 'Please input user email' }]}>
							<Input prefix={<MailOutlined />} />
						</Form.Item>
					</Form>
				</Modal>
			</div>
		</>
	);
};

export default withRouter(observer(Team));

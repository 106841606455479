import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Button from 'antd/es/button';
import PlusOutlinedSvg from '@ant-design/icons/PlusOutlined';

import { withRouter } from '../../../services/hooks/withRouter';

import { ProjectForm } from '../../../pages/projects/components/ProjectForm';

const HeaderProjectItem = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSave = useCallback((id: string) => {
    setIsModalOpen(false);
    navigate(`/project/${id}`);
  }, []);

  return (
    <>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlinedSvg/>}
        onClick={() => setIsModalOpen(true)}
      >New project</Button>
      {isModalOpen && (
        <ProjectForm isOpen={isModalOpen} onSave={onSave} onClose={() => setIsModalOpen(false)}/>
      )}
    </>
  );
};

export default withRouter(observer(HeaderProjectItem));

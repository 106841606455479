import Button from 'antd/es/button';
import notification from 'antd/es/notification'

import { SvgIcon } from '../../atoms/svg-icon';

import css from '../how-to-use-code-block/how-to-use-code-block.module.scss';

interface CopyButtonProps {
	content: string;
}

export const CopyButton = ({ content }: CopyButtonProps) => {

	return (
		<Button
			className={css.copyButton}
			onClick={() => {
				window.navigator.clipboard.writeText(content);
				notification.success({
					message: 'Sucess',
					description: 'Copied to clipboard.',
					duration: 3,
				});
			}}
		>
			<SvgIcon icon={'copy'} />
			<span className={css.copy}>Copy</span>
		</Button>
	);
};

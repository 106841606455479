import s from './statistic-items.module.scss';
import { StatisticItem } from '../../atoms/statistic-item';

interface StatisticItemsProps {
	bottleNecks: number;
	cases: number;
	nodes: number;
	problems: number;
}

export const StatisticItems = ({
	bottleNecks,
	cases,
	nodes,
	problems,
}: StatisticItemsProps) => (
	<div className={s.statItems}>
		<div className={s.statsRow}>
			<StatisticItem icon="events" value={nodes} />
			<StatisticItem icon="unique" value={cases} />
		</div>
		<div className={s.statsRow}>
			<StatisticItem icon="problems" value={problems} />
			<StatisticItem icon="errors" value={bottleNecks} />
		</div>
	</div>
);

import Table, { TableProps } from 'antd/es/table';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { GraphData } from '../../../types/interface/graphData';

import { ReactComponent as NodeCriticalSvg } from '../../../assets/images/node-critical.svg';
import { ReactComponent as NodeCasesSvg } from '../../../assets/images/node-regular.svg';

import { Errors } from './ErrorsDescriptions';

import css from './project-table.module.scss';

const supportedTypesSet = new Set(Object.keys(Errors));

function getIcon(types: string[]) {
  const filteredTypes = types.filter((type) => supportedTypesSet.has(type));
  if (filteredTypes.length > 1) {
    return NodeCriticalSvg;
  }

  const [nodeType] = filteredTypes;

  return Errors[nodeType]?.icon ?? NodeCasesSvg;
}

const columns: TableProps<TableRowData>['columns'] = [
  Table.EXPAND_COLUMN,
  {
    title: 'Customer Action',
    ellipsis: true,
    dataIndex: 'node',
    align: 'left',
    render: (_: string, rowData: TableRowData) => {
      const Icon = getIcon(rowData.type);

      return (
        <div className={css.name}><Icon className={css.name__icon} width="20px" height="20px"/>
          <span className={css.name__text}>{rowData.node}</span>
        </div>
      );
    },
  },
  {
    title: 'Conversion Rate',
    dataIndex: 'conversion',
    align: 'right',
    width: 100,
  },
  {
    title: 'Number of Sessions',
    dataIndex: 'frequency',
    align: 'right',
    width: 120,
    defaultSortOrder: 'descend',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => a.frequency - b.frequency,
  },
  {
    title: 'Loss',
    dataIndex: 'lossStr',
    align: 'right',
    width: 100,
    sorter: (a, b) => a.loss - b.loss,
  },
];

export type ProjectTableProps = {
  loading?: boolean;
  graphData?: GraphData | null;
}

type TableRowData = {
  id: string;
  node: string,
  conversion: string,
  frequency: number,
  type: string[],
  loss: number;
  lossStr: string;
}

const expandableConfig: TableProps<TableRowData>['expandable'] = {
  expandRowByClick: true,
  rowExpandable: (record) => record.type.some((type) => Object.keys(Errors).includes(type)),
  expandedRowRender: record => {
    const errorTypes = Object.keys(Errors);
    const description = record.type.reduce<string[]>((acc, type) => {
      if (errorTypes.includes(type)) {
        acc.push(Errors[type].content)
      }

      return acc;
    }, []).join('\n\n---\n\n');

    return (
      <div className={css.rowContent}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{description}</ReactMarkdown>
      </div>
    );
  },
};

export const ProjectTable = ({ graphData, loading }: ProjectTableProps) => {
  const nodes: TableRowData[] = graphData?.nodes
    .filter(({ visible }) => visible)
    .sort((a, b) => b.metrics.frequency - a.metrics.frequency)
    .map(
      (item) => {
        const typeNode = graphData?.type_nodes?.[item.label] ?? [];
        const conversion = Math.round(item.metrics.conversion * 10000) / 100;

        return {
          id: item.id,
          node: item.label,
          conversion: `${conversion}%`,
          frequency: item.metrics.frequency,
          type: typeNode,
          loss: item.loss_number ?? 0,
          lossStr: `$${Math.round(item?.loss_number ?? 0)}`,
        };
      },
    ) ?? [];

  return (
    <Table
      rowKey="id"
      dataSource={nodes}
      columns={columns}
      loading={loading}
      expandable={expandableConfig}
      pagination={{
        defaultPageSize: 50,
      }}
    />
  );
};

import s from './tab-contnet.module.scss';
import { useEffect, useState } from 'react';
import { ModalVideo } from '../../atoms/modal-video';
import { removeYoutubeScript, addYouTubeScript } from '../../../services/common/youtube-script';
import { HowToUseTabs } from '../how-to-use-tabs';

export const TabContent = () => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [showVideo, setVideoShow] = useState<boolean>(false);
	const [activeVideo, setActiveVideo] = useState<string>('');

	useEffect(() => {
		addYouTubeScript();

		return () => {
			removeYoutubeScript();
			setVideoShow(false);
			setIsModalOpen(false);
			setActiveVideo('');
		};
	}, []);

	const handleClick = () => {
		setVideoShow(true);
		setIsModalOpen(true);
	};

	return (
		<div className={s.tabContent}>
			<HowToUseTabs setActiveVideo={setActiveVideo} handleClick={handleClick} />
			{isModalOpen && showVideo && (
				<ModalVideo
					activeVideo={activeVideo}
					setIsModalOpen={setIsModalOpen}
					setVideoShow={setVideoShow}
				/>
			)}
		</div>
	);
};

import Column from '@ant-design/plots/es/components/column';

export type ColumnChartProps = {
  data: Record<string, unknown>[];
  xField: string;
  yField: string;
  seriesField: string;
};
export function ColumnChart({ data, yField, xField, seriesField }: ColumnChartProps) {
  return (
    <Column xField={xField} yField={yField} data={data} seriesField={seriesField} isGroup />
  );
}

import * as d3 from 'd3';
import { filters, download, upload, growth } from './graphIcons';

export const graphClear = (forceLink) => {
  const svg = d3.selectAll('g');
  forceLink.stop();
  svg.remove();
};

export const fullscreenToggle = (isFullscreen, setIsFullscreen) => setIsFullscreen(!isFullscreen);

export const graphUpdate = ({ options, graphData, userParams, setForceLink, graphRef, graphLayout, classes }) => {
  const {
    forceWidth,
    forceHeight,
    forceCharge,
    mainPathLeftPadding,
    mainPath,
  } = options;
  const {
    nodeFreqFilter,
    switchShowEdgeTitle,
    switchShowHiddenItems,
    switchUseSmartFilter,
  } = userParams;
  let translateShift = [0, 0]; // dif [x, y] when moving graph

  /**
   * Start graph vizualization
   */
  const svg = d3
    .select('svg.cabSvg')
    // zoom and pan(draging)
    .call(d3.behavior.zoom().scaleExtent([1, 1]).on('zoom', zoom))
    .on('wheel.zoom', null)
    .call(
      d3.behavior
        .drag()
        .on(
          'dragstart',
          () =>
            d3.event.sourceEvent.stopPropagation() &&
            d3.select('svg.cabSvg').style('cursor', 'move'),
        )
        .on(
          'dragend',
          () =>
            d3.event.sourceEvent.stopPropagation() &&
            d3.select('svg.cabSvg').style('cursor', 'default'),
        ),
    )
    .append('g');

  function zoom() {
    const zoom = d3.event;
    const bg = graphRef.current;
    svg.attr(
      'transform',
      'translate(' + zoom.translate + ')scale(' + zoom.scale + ')',
    );
    translateShift = d3.event.translate; // tooltip coordinates
    bg.style.backgroundPosition = `${zoom.translate[0]}px ${zoom.translate[1]}px`;
  }

  const tooltip = d3.select('#tooltip');

  const graph = graphData;

  const force = d3.layout
    .force()
    .size([forceWidth, forceHeight])
    .linkDistance(function (link) {
      if (graphLayout === 'tree') {
        return link.source.level === link.target.level ? 500 : 200;
      }
      return 20; //default
    })
    .charge(forceCharge);

  setForceLink(force);

  // save graph data for filtering
  const processedGraph = Object.assign({}, graph);
  const nodeIndexMap = new Map();
  processedGraph.nodes.forEach((n, i) => {
    nodeIndexMap.set(n.label, i);
    n.metrics.in = 0;
    n.metrics.out = 0;
  });

  console.log(processedGraph);

  processedGraph.edges.forEach((e) => {
    e.size = 300;
    e.source = nodeIndexMap.get(e.from) ?? 0;
    e.target = nodeIndexMap.get(e.to) ?? 0;

    const inNode = processedGraph.nodes[e.target];
    inNode.metrics.in = (inNode.metrics.in ?? 0) + e.metrics.frequency;

    const outNode = processedGraph.nodes[e.source];
    outNode.metrics.out = (inNode.metrics.out ?? 0) + e.metrics.frequency;
  });

  processedGraph.MainPath.forEach((v) => {
    const mainPathEdge = processedGraph.edges.find((e) => e.from === v.from && e.to === v.to);
    if (mainPathEdge) {
      mainPathEdge.main = 1;
    }
  });

  if (switchUseSmartFilter) {
    processedGraph.edges = processedGraph.edges.filter(v => v.visible);
    processedGraph.nodes = processedGraph.nodes.filter(v => v.visible);
  }

  if (!switchShowHiddenItems) {
    let filteredNodeIds = [];
    processedGraph.nodes = graph.nodes.filter(
      (v) => v.metrics.frequency > nodeFreqFilter && filteredNodeIds.push(v.id),
    );

    let filteredEdgeIds = [];
    processedGraph.edges = graph.edges.filter(
      (v, i) =>
        v.metrics.frequency > nodeFreqFilter &&
        filteredNodeIds.includes(v.source.id) &&
        filteredNodeIds.includes(v.target.id) &&
        filteredEdgeIds.push(i),
    );
  }

  // start force simulations
  force
    .nodes(processedGraph.nodes)
    .links(processedGraph.edges)
    .on('tick', tick)
    .start();

  // prepare all links
  const link = svg
    .selectAll('.link')
    .data(processedGraph.edges)
    .enter()
    .append('g')
    .attr('class', 'link');

  const edges = link
    .append('path')
    .style('fill', 'none') // curving line v2
    .attr('class', (edge) => {
      if (edge.metrics.frequency < nodeFreqFilter) {
        return classes.hiddenLine;
      }
      return classes.line;
    })
    .attr({
      id: function (d, i) {
        return 'edgepath' + i;
      },
    });

  if (switchShowEdgeTitle) {
    svg
      .selectAll('.edgelabel')
      .data(processedGraph.edges)
      .enter()
      .append('text')
      .attr('text-anchor', 'middle')
      .style('pointer-events', 'none')
      .attr('dy', '-5px')
      .attr({
        class: `edgelabel ${classes.edgeLabel}`,
        id: (d, i) => 'edgelabel' + i,
        fill: '#666666',
      })
      .append('textPath')
      .attr('xlink:href', function (d, i) {
        return '#edgepath' + i;
      })
      .style('pointer-events', 'none')
      .attr('startOffset', '50%')
      .text(function (d) {
        return `${
          d.metrics['frequency']
        }(${Math.round((d.metrics['perfomance'] + Number.EPSILON) * 100) / 100})`;
      });
  }

  // filter for lines, add thick separator
  edges.filter((d) => d.main).attr('class', classes.mainLine);

  // prepare all nodes
  const node = svg
    .selectAll('.node')
    .data(processedGraph.nodes)
    .enter()
    .append('g')
    .attr('class', 'node')
    .on('click', click);

  // Toggle children on click.
  async function click(d) {
    if (d3.event.defaultPrevented) {
      return; // ignore drag
    }

    if (d.children) {
      d._children = d.children;
      d.children = null;
    }
    else {
      d.children = d._children;
      d._children = null;
    }
  }

  const getNodeStyle = (node) => {
    if (nodeFreqFilter && node.metrics.frequency < nodeFreqFilter) {
      return 'url(#nodeHidden)';
    }

    const nodeType = graph.type_nodes[node.id];
    if (nodeType?.includes('Bottle Neck')) {
      return 'url(#nodeBottleneck)';
    }
    else if (node.drop_more_than_half) {
      return 'url(#nodeMassDrop)';
    }
    else if (node.waterfall_point) {
      return 'url(#nodeWaterfall)';
    }
    else if (nodeType?.includes('Problem')) {
      if (nodeType?.includes('Loop')) return 'url(#nodeProblemLoop)';
      return 'url(#nodeProblem)';
    }
    else if (node.broken_button) {
      return 'url(#nodeButton)';
    }
    else if (node.wondering_light) {
      return 'url(#nodeLight)';
    }
    else if (nodeType?.includes('Border')) {
      return node.id === '_start' ? 'url(#nodeStart)' : 'url(#nodeFinish)';
    }
    else {
      return 'url(#nodeCases)';
    }
  };

  const isSystemNode = (node) => node.id[0] === '_';

  node // show tooltip for node
    .filter((node) => !isSystemNode(node)) // filter all nodes but start/finish
    .on('mouseover', function (d) {
      tooltip.transition().duration(150).style('opacity', 1);

      const block = tooltip
        .html(``)
        .style('left', d.x - 202 + translateShift[0] + 'px')
        .style('top', d.y - 20 + translateShift[1] + 'px');

      const getTooltipTitleElement = (title) =>
        title.length > 28
          ? `<marquee direction="left" scrollamount="6">${d.label}</marquee>`
          : title;

      // metrics on left side (supposed to be 2)
      block.append('p').html(getTooltipTitleElement(d.label));
      block
        .append('div')
        .html(
          `<span>${growth}${d.metrics.frequency}</span><span>${upload}${d.metrics.out}</span>`,
        );
      block
        .append('div')
        .html(
          `<span>${filters}${Math.round((d.metrics.conversion + Number.EPSILON) * 100)}%</span><span>${download}${d.metrics.in}</span>`,
        );
      block
        .append('div')
        .html(
          `LOSS $${Math.round(Number(d.loss_number || 0))}`,
        )
        .style('color', '#DE1C22');

      block
        .append('a')
        .attr('xlink:href', `http://${d.id}`)
        .append('path')
        .attr('d', 'M13 0L27 8L27 24L13 32L0 24 L0 8Z')
        .style({
          width: '28px',
          height: '28px',
          position: 'absolute',
          top: '5px',
          right: '7px',
        });
    })
    .on('mouseout', () => {
      tooltip.transition().duration(200).style('opacity', 1);
    })
    .call(force.drag().on('dragstart', dragstart)); // drug nodes

  function dragstart(d) {
    d3.event.sourceEvent.preventDefault();
    d3.event.sourceEvent.stopPropagation();
    d3.select(this).classed('fixed', (d.fixed = true));
  }

  // filter for nodes, ?
  node
    .filter(function (d) {
      return d.bond > 1;
    })
    .attr('class', classes.line)
    .attr('x', '300px')
    .attr('class', 'separator');

  node
    .append('path')
    .attr('d', 'M20 0L40 10L40 30L20 40L0 30 L0 10Z')
    // node background style
    .style('filter', getNodeStyle)
    .attr('transform', 'translate(-14, -16)');

  // add label name to node
  node
    .filter((node) => !isSystemNode(node)) // except start/finish nodes
    .append('text')
    .attr('dx', '30px')
    .attr('dy', '-20px')
    .attr('text-anchor', 'left')
    .attr('class', classes.nodeText)
    .text((d) => d.label);

  // add value number to node
  node
    .append('text')
    .attr('dx', '20px')
    .attr('dy', '-15px')
    .attr('text-anchor', 'left')
    .attr('class', classes.nodeValue)
    .text(function (d) {
      return d.value;
    });

  function tick() {

    node.attr('transform', function (d) {
      // lock nodes only for vertical main path
      if (mainPath) {
        // lock nodes inside box
        if (d.main) {
          if (d.x < mainPathLeftPadding) {
            d.x = mainPathLeftPadding;
          }
        }
        else {
          if (d.x < mainPathLeftPadding + 10) {
            d.x = mainPathLeftPadding + 10;
          }
        }
      }

      if (graphLayout === 'tree') {
        const levelHeight = [
          0,
          50,
          300,
          600,
          900,
          1200,
          1500,
          1800,
          2100,
          2400,
        ];

        if (!d.fixed && d.level) {
          if (!d.rand) {
            const min = -90;
            const max = 90;
            d.rand = Math.round(min - 0.5 + Math.random() * (max - min + 1));
          }

          d.y = levelHeight[d.level] + d.rand;
        }
      }
      return 'translate(' + d.x + ',' + d.y + ')';
    });

    link
      .selectAll('path') // curving line v1
      .attr('d', function (d) {

        let x1 = d.source.x,
          y1 = d.source.y,
          x2 = d.target.x,
          y2 = d.target.y,
          dx = x2 - x1,
          dy = y2 - y1,
          dr = Math.sqrt(dx * dx + 2 * dy * dy),
          // Defaults for normal edge.
          drx = dr,
          dry = dr,
          xRotation = 0, // degrees
          largeArc = 0, // 1 or 0
          sweep = 1; // 1 or 0

        // Self edge.
        if (x1 === x2 && y1 === y2) {
          // Fiddle with this angle to get loop oriented.
          xRotation = -45;

          // Needs to be 1.
          largeArc = 1;

          // Change sweep to change orientation of loop.
          //sweep = 0;

          // Make drx and dry different to get an ellipse
          // instead of a circle.
          drx = 30;
          dry = 30;

          // For whatever reason the arc collapses to a point if the beginning
          // and ending points of the arc are the same, so kludge it.
          x2 = x2 + 1;
          y2 = y2 + 1;
        }
        else {
          drx = 0;
          dry = 0;
        }

        // lock only for vertical main path
        if (mainPath) {
          // lock nodes inside box
          if (d.target.main || d.source.main) {
            if (x1 < mainPathLeftPadding) x1 = mainPathLeftPadding;

            if (x2 < mainPathLeftPadding) x2 = mainPathLeftPadding;
          }
          else {
            if (x1 < mainPathLeftPadding + 10) x1 = mainPathLeftPadding + 10;

            if (x2 < mainPathLeftPadding + 10) x2 = mainPathLeftPadding + 10;
          }
        }

        return (
          'M' +
          x1 +
          ',' +
          y1 +
          'A' +
          drx +
          ',' +
          dry +
          ' ' +
          xRotation +
          ',' +
          largeArc +
          ',' +
          sweep +
          ' ' +
          x2 +
          ',' +
          y2
        );
      });
  }
};

import { useState } from 'react';
import s from './action-panel.module.scss';
import { CustomButton } from '../../atoms/custom-button';
import { SearchBlock } from '../../atoms/search-block';
import { DropdownSelector } from '../../atoms/dropdown-selector';
import { Modal } from '../../organisms/modal';

interface ActionPanelPanel {
	useSearch?: boolean;
	setFilteredBy?: (value: string) => void;
	onClick?: () => void;
	setSelectedSort?: (value: string) => void;
	setUpdate: (value: boolean) => void;
	buttonTitle?: string;
	selectedValue?: string;
	dropdownSelector?: boolean;
	withInfo?: boolean;
	infoComponent?: React.ReactNode;
}
export const ActionPanel = ({
	useSearch,
	setFilteredBy,
	onClick,
	setSelectedSort,
	setUpdate,
	buttonTitle,
	selectedValue,
	dropdownSelector,
	withInfo,
	infoComponent,
}: ActionPanelPanel) => {
	const [showInfo, setShowInfo] = useState<boolean>(false);
	const [infoElement, setInfoElement] = useState<HTMLDivElement | null>(null);
	return (
		<div className={s.actionPanel}>
			<div className={s.newButtonPanel}>
				{withInfo && (
					<CustomButton
						onClick={() => setShowInfo(true)}
						withIcon
						icon="info"
						className={s.infoButton}
					/>
				)}
				{useSearch && setFilteredBy && (
					<SearchBlock setFilteredBy={setFilteredBy} setUpdate={setUpdate} />
				)}
				<div className={s.actions}>
					{dropdownSelector && selectedValue && setSelectedSort && (
						<DropdownSelector
							selectedValue={selectedValue}
							onSelect={setSelectedSort}
							setUpdate={setUpdate}
							items={['Recent', 'Alphabetical']}
						/>
					)}

					{onClick && (
						<CustomButton
							className={s.newButton}
							type="default"
							onClick={onClick}
							text={buttonTitle}
							withIcon
							icon="plus"
							size="middle"
						/>
					)}
				</div>
			</div>
			<div ref={setInfoElement}>
				{showInfo && (
					<Modal setIsModalOpen={setShowInfo} referenceElement={infoElement}>
						{infoComponent}
					</Modal>
				)}
			</div>
		</div>
	);
};

import Input from 'antd/es/input';

const { Search } = Input;

interface SearchBlockProps {
  setFilteredBy: (value: string) => void;
  setUpdate: (value: boolean) => void;
}

export const SearchBlock = ({ setFilteredBy, setUpdate }: SearchBlockProps) => {
  const onSearch = (value: string) => (value.length > 0 ? setFilteredBy(value) : setFilteredBy(''));

  return (
    <Search
      placeholder="Search"
      allowClear
      size="middle"
      onSearch={(e) => {
        onSearch(e.toLowerCase());
        setUpdate(true);
      }}
      style={{ width: 298 }}
    />
  );
};

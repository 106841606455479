import React from 'react';

import Scatter from '@ant-design/plots/es/components/scatter';

export type BubbleChartProps = {
  data: Record<string, unknown>[];
  xField: string;
  yField: string;
  sizeField: string;
  size?: [number, number];
};

const axisConfig = {
  yAxis: {
    nice: true,
    line: {
      style: {
        stroke: '#aaa',
      },
    },
  },
  xAxis: {
    grid: {
      line: {
        style: {
          stroke: '#eee',
        },
      },
    },
    line: {
      style: {
        stroke: '#aaa',
      },
    },
  },
};

export const BubbleChart = React.memo(function BubbleChart({
  data,
  xField,
  yField,
  sizeField,
  size = [5, 15],
}: BubbleChartProps) {

  return (
    <Scatter
      xField={xField}
      yField={yField}
      sizeField={sizeField}
      size={size}
      data={data}
      xAxis={axisConfig.xAxis}
      yAxis={axisConfig.yAxis}
    />
  );
});

export const letUpload = (
	upload: React.MutableRefObject<HTMLInputElement | null | undefined>,
	setSent: (value: boolean) => void
) => {
	if (upload) {
		if (upload.current) {
			upload.current?.click();
			upload.current.onchange = function () {
				setSent(true);
			};
		}
	}
};

import s from './projects-manual.module.scss';
import { DashboardManualStep } from '../../atoms/dashboard-manual-step';

export const ProjectsManual = () => (
	<>
		<div className={s.titleBlock}>
			<h2 className={s.title}>Here is your InsightArc dashboard</h2>
			<p className={s.description}>Click + New button to start your first project</p>
		</div>
		<DashboardManualStep />
	</>
);

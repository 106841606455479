import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ConfigProvider, { type ThemeConfig } from 'antd/es/config-provider';

import StoreProvider from './services/stores/provider';
import store from './services/stores/store';
import auth from './services/stores/auth';
import App from './App';

const appStore = store.create(
  {},
  {
    auth,
  },
);

const themeConfig: ThemeConfig = {
  token: {
    colorPrimary: '#12b76a',
  },
  components: {
    Breadcrumb: {
      iconFontSize: 20,
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider theme={themeConfig}>
      <StoreProvider store={appStore}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StoreProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

interface NumberFormatProps {
	item: number;
	type?: string;
	fraction?: number;
}
export const NumberFormat = ({ item, type = 'decimal', fraction = 2 }: NumberFormatProps) => {
	return new Intl.NumberFormat('ru-RU', {
		style: type,
		maximumFractionDigits: fraction,
		minimumFractionDigits: 0,
	}).format(item as number);
};

import { useMemo } from 'react';

import AntdTreeMap from '@ant-design/plots/es/components/treemap';

import css from './TreeMap.module.scss';

export type TreeMapProps = {
  data: {
    name: string;
    value: number;
    type: string;
    children: { name: string; value: number; }[]
  }[];
  colorField: string;
};

export function TreeMap({ data, colorField }: TreeMapProps) {
  const treeData = useMemo(() => {
    return {
      name: 'root',
      children: data,
    };
  }, [data]);

  return (
    <AntdTreeMap
      data={treeData}
      tooltip={{
        customContent(_, item) {
          const [node] = item;
          if (!node) {
            return;
          }
          const { data } = node.data;

          return (
            <div className={css.tooltip}>
              <div className={css.tooltip__text}><b>Trace:</b> {data.trace}</div>
              <div className={css.tooltip__text}><b>Node:</b> {data.name}</div>
              <div className={css.tooltip__text}><b>Value:</b> {data.value}</div>
              <div className={css.tooltip__text}><b>Frequency:</b> {data.frequency}</div>
              <div className={css.tooltip__text}><b>Conversion:</b> {data.conversion}</div>
            </div>
          );
        },
      }}
      colorField={colorField}
    />
  );
}

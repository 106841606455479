import s from './sign-up-button.module.scss';
import cn from 'classnames';
import { CustomButton } from '../custom-button';

interface SignUpButtonProps {
	formType: string;
	setFormType: (value: string) => void;
	isAlone?: boolean;
}

export const SignUpButton = ({ formType, setFormType, isAlone = false }: SignUpButtonProps) => {
	return (
		<div className={cn(s.signUpLink, { [s.isAlone]: isAlone })}>
			<CustomButton
				type="link"
				text={formType}
				className={s.textButton}
				onClick={() => setFormType(formType)}
			/>
		</div>
	);
};

import { useState, useCallback } from 'react';

import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';

import Tooltip from 'antd/es/tooltip';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import List from 'antd/es/list';
import Modal from 'antd/es/modal';
import Typograthy from 'antd/es/typography';

import { IProjectsModel, ProjectIntegrationsModel } from '../../../../../../services/stores/projects';
import { useStore } from '../../../../../../services/stores/use-store';

import { AddOrEditIntegrationModal } from './AddOrEditIntegrationModal';

export type ProjectIntegrationsProps = {
  project: IProjectsModel;
};

export function ProjectIntegrations({ project }: ProjectIntegrationsProps) {
  const { projects } = useStore();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [editingIntegration, setEditingIntegration] = useState<ProjectIntegrationsModel>();

  const convertflowIntegrations = project.integrations.filter(integration => integration.provider === 'convertflow');

  const onDelete = useCallback((integration: ProjectIntegrationsModel) => {
    console.log('onDelete', integration);
    Modal.confirm({
      title: `Are you sure you want to remove "${integration.name}" integration for "${project.name}"?`,
      content: 'This action can not be undone.',
      okType: 'danger',
      onOk: async () => {
        await projects.deleteIntegration(project.id, integration.id);
      },
      onCancel: () => {
      },
    });
  }, []);

  const onEdit = useCallback((integration: ProjectIntegrationsModel) => {
    setEditingIntegration(integration);
    setIsModalOpened(true);
  }, []);

  const isIntegrationModalOpened = !!editingIntegration || isModalOpened;

  return (
    <Space
      direction="vertical"
      style={{ width: '100%' }}
    >
      <ConvertFlowIntegration integrations={convertflowIntegrations} onEdit={onEdit} onDelete={onDelete}/>
      {convertflowIntegrations.length === 0 && (
        <Space>
          <Button
            icon={<PlusOutlined/>}
            onClick={() => setIsModalOpened(true)}
          >Add integration</Button>
          <Tooltip title="How to add integration?">
            <Button
              href="https://www.insightarc.com/blog/Integrations/integrating-insightarc-signal-with-convertflow-a-step-by-step-guide/"
              type="ghost"
              icon={<InfoCircleOutlined/>}
              aria-label="How to add integtaion?"
              target="_blank"
              rel="noopener noreferrer"
            />
          </Tooltip>
        </Space>
      )}
      {isIntegrationModalOpened && (
        <AddOrEditIntegrationModal
          projectId={project.id}
          integration={editingIntegration}
          isOpen={isIntegrationModalOpened}
          onClose={() => {
            console.log('Close modal');
            setEditingIntegration(undefined);
            setIsModalOpened(false);
          }}
        />
      ) }
    </Space>
  );
}

type ConvertFlowIntegrationProps = {
  integrations: ProjectIntegrationsModel[];
  onEdit?(integration: ProjectIntegrationsModel): void;
  onDelete?(integration: ProjectIntegrationsModel): void;
};

function ConvertFlowIntegration({ integrations, onDelete, onEdit }: ConvertFlowIntegrationProps) {
  return (
    <Space.Compact direction="vertical" block>
      <Typograthy.Title level={5} style={{marginTop: 0}}>Convertflow</Typograthy.Title>
      {integrations.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={integrations}
          bordered
          renderItem={(item) => (
            <List.Item
              key={item.id}
              actions={[
                <Tooltip title="Edit integtation" key="edit">
                  <Button icon={<EditOutlined/>} aria-label="Edit integration" onClick={() => onEdit?.(item)}/>
                </Tooltip>,
                <Tooltip title="Delete integtation" key="delete">
                  <Button danger icon={<DeleteOutlined/>} aria-label="Delete integration" onClick={() => onDelete?.(item)}/>
                </Tooltip>,
              ]}
            >
              <List.Item.Meta
                title={item.name}
                description={`CTA ID: ${item.data.ctaId}`}
              />
            </List.Item>
          )}
        />
      )}
    </Space.Compact>
  );
}

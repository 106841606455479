import Menu from 'antd/es/menu';

type DropdownMenuItemProps = {
	items: string[];
	onSelect: (value: string) => void;
}

export const DropdownMenuItem = ({ items, onSelect }: DropdownMenuItemProps) => (
	<Menu onClick={(item) => onSelect(item.key as unknown as string)}>
		{items.map((item) => (
			<Menu.Item key={item}>{item}</Menu.Item>
		))}
	</Menu>
);

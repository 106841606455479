import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import { Error } from './error';
import { routes } from './routes';
import Auth from './auth';
import { observer } from 'mobx-react-lite';
import { Layout } from '../compoments/templates/layout';
import { useStore } from '../services/stores/use-store';
import { withRouter } from '../services/hooks/withRouter';

import { ProjectDashboard, ProjectGraph } from './project';

const Router = (): JSX.Element | null => {
  const { authStore, projects, getMainRoutes, appLoaded, getAuthRoutes } = useStore();

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    authStore.checkAuth();
  }, []);

  useEffect(() => {
    if (!getMainRoutes || authStore.isAuthLoading) {
      return;
    }

    if (authStore.isAuthenticated) {
      projects.getProjects().then(() => {
        if (getMainRoutes.includes(pathname)) {
          navigate('/projects');
        } else if (!getMainRoutes.includes(pathname)) {
          navigate(pathname);
        }
      }).catch((e) => {
        console.warn('Error while getting projects');
        console.error(e);
      });

    } else {
      if (pathname.includes('/verify/')) {
        const token = pathname.substring(8);
        authStore.setEnterToken(token);
        authStore.setAuthState('setUpPasswordForm');
      } else {
        navigate('/auth');
        authStore.setAuthState('Sign In');
      }
    }
    setIsLoading(false);
  }, [authStore.isAuthenticated, authStore.isAuthLoading]);

  if (!appLoaded) {
    return null;
  }

  if (authStore.isAuthenticated) {
    return (
      <Layout pageTitle={routes.find(({ path }) => path === pathname)?.name || 'Project'}>
        <Routes>
          <Route path="/project/:id">
            <Route index element={<ProjectDashboard />}/>
            <Route path="graph" element={<ProjectGraph />}/>
          </Route>
          {!isLoading &&
            routes.map(({ path, component }) => (
              <Route key={path} path={path} element={React.createElement(component)}></Route>
            ))}
          {!isLoading && <Route element={<Error/>}/>}
        </Routes>
      </Layout>
    );
  }

  if (!authStore.isAuthLoading) {
    return (
      <Routes>
        {getAuthRoutes.map((path) => (
          <Route key={path} path={path} element={<Auth/>}/>
        ))}
      </Routes>
    );
  }

  return null;
};

export default withRouter(observer(Router));

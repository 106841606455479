import s from './upload-export.module.scss';
import { CustomButton } from '../../atoms/custom-button';

export const UploadExport = () => (
	<div className={s.actionButtons}>
		<CustomButton
			size="middle"
			type="default"
			withIcon
			icon="upload"
			onClick={() => {}}
			text="Upload"
			className={s.upload}
		/>
		<CustomButton
			size="middle"
			type="default"
			withIcon
			icon="export"
			onClick={() => {}}
			text="Export"
			className={s.export}
		/>
	</div>
);

import { observer } from 'mobx-react-lite';
import { SignInForm } from '../../molecules/sigin-form';
import SignUpForm from '../../molecules/sigup-form';
import s from './auth-form.module.scss';
import cn from 'classnames';
import { SvgIcon } from '../../atoms/svg-icon';
import ForgottenPasswordForm from '../../molecules/forgotten-password-form';
import ConfirmPasswordForm from '../../molecules/confirm-password-form';
import Confirmation from '../../molecules/confirmation';
import SetUpPasswordForm from '../../molecules/set-up-password-form';
import { useStore } from '../../../services/stores/use-store';
import { withRouter } from '../../../services/hooks/withRouter';

const AuthForm = () => {
	const {
		authStore,
	} = useStore();

	console.log(authStore.authState);

	let form;
	switch (authStore.authState) {
		case 'Sign In': {
			form = <SignInForm />;
			break;
		}
		case 'Sign Up': {
			form = <SignUpForm />;
			break;
		}
		case 'forgottenPassword': {
			form = <ForgottenPasswordForm />;
			break;
		}
		case 'confirmPassword': {
			form = <ConfirmPasswordForm />;
			break;
		}
		case 'confirmation': {
			form = <Confirmation />;
			break;
		}
		case 'setUpPasswordForm': {
			form = <SetUpPasswordForm />;
			break;
		}
		default: {
			form = <SignInForm />;
			break;
		}
	}
	const formHeight = authStore.authState === 'Sign In';
	const currentYear = new Date().getFullYear();

	return (
		<div className={s.signUpForm}>
			<div className={s.wrap}>
				<div className={s.form}>
					{authStore.authState !== 'confirmation' && (
						<div
							className={cn(s.logo, {
								[s.signInLogo]: formHeight,
							})}
						>
							<SvgIcon icon="logo" />
						</div>
					)}
					{form}
				</div>
				<div className={s.copyrights}>
					<span>InsightArc {currentYear}</span>
				</div>
			</div>
		</div>
	);
};

export default withRouter(observer(AuthForm));

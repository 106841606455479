import { Portal } from '../../../services/common/portal';
import s from './modal-video.module.scss';
import { usePopper } from 'react-popper';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import {
	youTubeCode,
	addYouTubeScript,
	removeYoutubeScript,
} from '../../../services/common/youtube-script';

interface ModalVideoProps {
	setIsModalOpen: (value: boolean) => void;
	setVideoShow: (value: boolean) => void;
	activeVideo: string;
}

export const ModalVideo = ({ setIsModalOpen, setVideoShow, activeVideo }: ModalVideoProps) => {
	const width = 800;
	const height = 600;
	const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
	const { attributes, styles } = usePopper(referenceElement, popperElement, {});

	useEffect(() => {
		const isAdded = addYouTubeScript()
		if (isAdded) youTubeCode(activeVideo, width, height);

		return () => removeYoutubeScript();
	}, []);

	return (
		<div ref={setReferenceElement}>
			<Portal>
				<div className="modal">
					<button
						onClick={() => {
							setIsModalOpen(false);
							setVideoShow(false);
						}}
						className="clickOutSide dark"
					/>
					<div
						className={cn('ant-modal', 'player', s.player)}
						style={styles.popper}
						{...attributes.popper}
						ref={setPopperElement}
					>
						<div className={cn('ant-modal-content', s.playerContent)} style={{ width, height }}>
							<div id="player" />
						</div>
					</div>
				</div>
			</Portal>
		</div>
	);
};

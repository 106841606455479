import Tooltip from 'antd/es/tooltip';

import { GraphData } from '../../../../../../types/interface/graphData';

import { ReactComponent as NodeSvg } from '../../../../../../assets/images/node-regular.svg';
import { ReactComponent as BottleneckNodeSvg } from '../../../../../../assets/images/node-bottleneck.svg';
import { ReactComponent as ErrorNodeSvg } from '../../../../../../assets/images/errors-icon.svg';

import css from './ProjectStats.module.scss';

export type ProjectStatsProps = {
  data: GraphData,
};

export function ProjectStats({ data }: ProjectStatsProps) {
  return (
    <div className={css.wrapper}>
      <div className={css.title}>{`${data.aggregate_info.cases} Cases`}</div>
      <div className={css.stats}>
        <Tooltip title="Nodes">
          <div className={css.stats__item}>
            <NodeSvg className={css.stats__icon}/>
            <div className={css.stats__value}>{data.aggregate_info.nodes}</div>
          </div>
        </Tooltip>
        <Tooltip title="Bottle Necks">
          <div className={css.stats__item}>
            <BottleneckNodeSvg className={css.stats__icon}/>
            <div className={css.stats__value}>{data.aggregate_info.bottle_necks}</div>
          </div>
        </Tooltip>
        <Tooltip title="Errors">
          <div className={css.stats__item}>
            <ErrorNodeSvg className={css.stats__icon}/>
            <div className={css.stats__value}>{data.aggregate_info.problems}</div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

import s from './how-to-use-code-block.module.scss';
import { CodeBlock, dracula } from 'react-code-blocks';
import { CopyButton } from '../copy-button';
import { useEffect, useState } from 'react';

interface HowToUseCodeBlockProps {
	content: string;
}

export const HowToUseCodeBlock = ({ content }: HowToUseCodeBlockProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const CopyCodeBlock = () => (
		<CodeBlock
			codeBlock
			theme={dracula}
			showLineNumbers={false}
			startingLineNumber={1}
			wrapLines
			text={content}
			language="text"
		/>
	);

	useEffect(() => {
		if (isLoading && content) {
			setIsLoading(false);
		}
	}, []);

	return (
		<div className={s.codeBlock}>
			{!isLoading && <CopyCodeBlock />}
			<CopyButton content={content} />
		</div>
	);
};

import s from './statistic-item.module.scss';
import { SvgIcon } from '../svg-icon';
import cn from 'classnames';

interface StatisticItemProps {
	icon: string;
	className?: string;
	value: string | number;
}

export const StatisticItem = ({ icon, value, className }: StatisticItemProps) => (
	<div className={cn(s.statsItem, className && className)}>
		<SvgIcon icon={icon} />
		<span>{value}</span>
	</div>
);

import s from './profile.module.scss';
import cn from 'classnames';

import { UserAvatar } from '../../compoments/molecules/user-avatar';
import { observer } from 'mobx-react-lite';
import { UserForm } from '../../compoments/molecules/user-form';
import { useStore } from '../../services/stores/use-store';
import { withRouter } from '../../services/hooks/withRouter';

const Profile = () => {
	const {
		authStore
	} = useStore()

	if (!authStore.user) {
		return null;
	}

	return (
		<div className={s.profile}>
			<div className={cn('avatar', s.profileAvatar)}>
				<UserAvatar email={authStore.user.email} size={88} />
			</div>
			<UserForm user={authStore.user} />
		</div>
	);
};

export default withRouter(observer(Profile));

import s from './custom-tab.module.scss';
import cn from 'classnames';

interface CustomTabProps {
	setActiveKey: (value: string) => void;
	id: string;
	title: string;
	icon: string;
	activeKey: string;
}

export const CustomTab = ({ setActiveKey, id, title, icon, activeKey }: CustomTabProps) => {
	return (
		<div
			key={id}
			className={cn(s.customTab, { [s.isActiveTab]: activeKey === id })}
			onClick={() => setActiveKey(id)}
		>
			<img className={s.icon} src={icon} alt={title} /> <span className={s.title}>{title}</span>
		</div>
	);
};

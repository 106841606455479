import { NotificationAlert } from '../../atoms/notification-alert';
import { Spinner } from '../../atoms/spinner';

interface RequestStatusNotificationProps {
	text: string;
	isLoading: boolean;
	showSuccess: boolean;
}

export const RequestStatusNotification = ({
	text,
	isLoading,
	showSuccess,
}: RequestStatusNotificationProps) => {
	return (
		<>
			{!isLoading && showSuccess && <NotificationAlert text={text} />}
			{isLoading && <Spinner />}
		</>
	);
};

import React, { useCallback } from 'react';
import Menu from 'antd/es/menu';

import css from '../../organisms/team-list/team-list.module.scss';

type EditProjectMenuProps = {
  onEdit: () => void;
  onSetToken: () => void;
  onCopyToken: () => void;
  hasToken: boolean;
}

export const EditProjectMenu = ({
                                  onEdit,
                                  onSetToken,
                                  onCopyToken,
                                  hasToken,
                                }: EditProjectMenuProps) => {
  const onClick = useCallback((item: {
    key: string;
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
  }) => {
    switch (item.key) {
      case 'Edit':
        onEdit();
        break;
      case 'SetToken':
        onSetToken();
        break;
      case 'CopyToken':
        onCopyToken();
        break;
      default:
        break;
    }
  }, []);

  return (
    <Menu className={css.alignRight} onClick={onClick}>
      <Menu.Item key="Edit">Edit project</Menu.Item>
      <Menu.Divider/>
      {!hasToken && (
        <Menu.Item key="SetToken">Generate API token</Menu.Item>
      )}
      {hasToken && (
        <Menu.Item key="CopyToken">Copy API token</Menu.Item>
      )}
    </Menu>
  );
};

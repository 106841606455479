import { CustomInput } from '../../atoms/custom-input';
import styles from '../../organisms/auth-form/form-items.module.scss';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../atoms/custom-button';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../services/stores/use-store';
import { RequestStatusNotification } from '../request-status-notification';
import { withRouter } from '../../../services/hooks/withRouter';

const ConfirmPasswordForm = () => {
	const { authStore } = useStore();
	const { setAuthState, setConfirmationText, enterToken, letEnterPassword } = authStore;
	const [password, setPassword] = useState<string>('');
	const [repeatedPassword, setRepeatedPassword] = useState<string>('');
	const [sendForm, setSendForm] = useState<boolean>(false);
	const [redirect, setRedirect] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [statusMessage, setStatusMessage] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		let redirectTimeOut: NodeJS.Timeout;
		if (sendForm) {
			if (password === repeatedPassword) {
				setIsLoading(true);
				letEnterPassword(enterToken, password).then(({ status, message }) => {
					if (status) {
						if (status === 200) {
							setSuccess(true);
							const redirect = function goToConfirm() {
								setSendForm(false);
								setRedirect(true);
								clearTimeout(redirectTimeOut);
							};
							redirectTimeOut = setTimeout(redirect, 2500);
						} else {
							setError(true);
							if (message) {
								setStatusMessage(message);
							}
						}
						setIsLoading(false);
					}
				});
			} else {
				setError(true);
				setStatusMessage('Check your passwords');
			}
			setPassword('');
			setRepeatedPassword('');
		}
	}, [sendForm]);

	useEffect(() => {
		if (redirect) {
			setRedirect(false);
			setConfirmationText('Your password has been changed');
			setAuthState('confirmation');
		}
	}, [redirect]);

	useEffect(() => {
		let changeErrorTimeOut: NodeJS.Timeout;
		if (error) {
			const clearError = function clearStatus() {
				setError(false);
				setStatusMessage('');
				setSendForm(false);
				setIsDisabled(false);
				clearTimeout(changeErrorTimeOut);
			};
			changeErrorTimeOut = setTimeout(clearError, 2500);
		}
	}, [error]);

	return (
		<>
			{success && !error && (
				<RequestStatusNotification
					text="Set up your new password"
					isLoading={isLoading}
					showSuccess={success && statusMessage.length === 0}
				/>
			)}
			<CustomInput
				labelClassName={styles.formItem}
				onChangeString={setPassword}
				type="password"
				placeholder="password"
				name="password"
				value={password}
				withIcon
				isDisabled={isDisabled}
			/>
			<CustomInput
				labelClassName={styles.formItem}
				onChangeString={setRepeatedPassword}
				type="password"
				placeholder="repeat password"
				name="repeatPassword"
				value={repeatedPassword}
				withIcon
				isDisabled={isDisabled}
				error={error}
				errorMessage={statusMessage.length > 0 ? statusMessage : undefined}
			/>
			<CustomButton
				isDisabled={isDisabled}
				type="text"
				className={styles.signInButton}
				text="Confirm"
				onClick={() => {
					if (password && repeatedPassword) {
						setIsDisabled(true);
						setSendForm(true);
					}
				}}
			/>
		</>
	);
};

export default withRouter(observer(ConfirmPasswordForm));

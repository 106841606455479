import { FunctionComponent } from 'react';

import BottleNeck from '!!raw-loader!./docs/BottleNeck.md';
import RepetitiveActions from '!!raw-loader!./docs/RepetitiveActions.md';
import CriticalIssue from '!!raw-loader!./docs/CriticalIssue.md';
import HighExitProbability from '!!raw-loader!./docs/HighExitProbability.md';
import HighDropoffPoint from '!!raw-loader!./docs/HighDropoffPoint.md';
import BrokenButton from '!!raw-loader!./docs/BrokenButton.md';
import WonderingLight from '!!raw-loader!./docs/WonderingLight.md';
import WaterfallPoint from '!!raw-loader!./docs/WaterfallPoint.md';

import { ReactComponent as NodeBottleneckSvg } from '../../../assets/images/node-bottleneck.svg';
import { ReactComponent as NodeLoopSvg } from '../../../assets/images/subnode-repetitive.svg';
import { ReactComponent as NodeProblemSvg } from '../../../assets/images/problem.svg';
import { ReactComponent as NodeHdpSvg } from '../../../assets/images/node-hdp.svg';
import { ReactComponent as NodeMassDropSvg } from '../../../assets/images/node-massdrop.svg';
import { ReactComponent as NodeButtonSvg } from '../../../assets/images/node-button.svg';
import { ReactComponent as NodeLightSvg } from '../../../assets/images/node-flashlight.svg';
import { ReactComponent as NodeWaterfall } from '../../../assets/images/node-waterfall.svg';

export const Errors: Record<string, { content: string; icon: FunctionComponent }> = {
  'Bottle Neck': { content: BottleNeck, icon: NodeBottleneckSvg },
  'Loop': { content: RepetitiveActions, icon: NodeLoopSvg },
  'Problem': { content: CriticalIssue, icon: NodeProblemSvg },
  'High drop probability': { content: HighExitProbability, icon: NodeHdpSvg },
  'Drop more than half': { content: HighDropoffPoint, icon: NodeMassDropSvg },
  'Broken button': { content: BrokenButton, icon: NodeButtonSvg },
  'Wondering light': { content: WonderingLight, icon: NodeLightSvg },
  'Waterfall point': { content: WaterfallPoint, icon: NodeWaterfall },
}

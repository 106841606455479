import Modal from 'antd/es/modal';
import Form from 'antd/es/form';

import { IProjectsModel } from '../../../services/stores/projects';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import { useCallback, useState } from 'react';
import { useStore } from '../../../services/stores/use-store';

export type ProjectFormProps = {
  isOpen: boolean;
  project?: IProjectsModel;
  onSave?: (projectId: string) => void;
  onClose(): void;
};

export function ProjectForm({ isOpen, project, onSave, onClose }: ProjectFormProps) {
  const { projects } = useStore();
  const [isBusy, setIsBusy] = useState(false);

  const [form] = Form.useForm();

  const onSaveClick = useCallback(async () => {
    try {
      const data = await form.validateFields();

      setIsBusy(true);

      if (!project) {
        await projects.createProject({
          name: data.name,
          average: data.average,
        }, onSave);
      } else {
        await projects.updateProject(project.id, {
          name: data.name,
          average: data.average,
        });

        onClose();
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsBusy(false);
    }
  }, [project]);

  return (
    <Modal
      open={isOpen}
      title={project ? 'Edit project' : 'Create project'}
      confirmLoading={isBusy}
      onOk={onSaveClick}
      onCancel={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        name="projectInfo"
        autoComplete="off"
        initialValues={{
          name: project?.name,
          average: project?.average ?? undefined,
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter project name', type: 'string' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="average"
          label="Average Ticket Size"
          rules={[{ required: true, message: 'Please enter value', type: 'number' }]}>
          <InputNumber type="number" style={{width: '100%'}} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

import { useEffect, useState } from 'react';
import Table from 'antd/es/table';
import Space from 'antd/es/space';
import Spin from 'antd/es/spin';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { ActionPanel } from '../../compoments/organisms/action-panel';
import { columns } from '../../assets/mock/api-keys-table';
import { useStore } from '../../services/stores/use-store';
import { IApiKeysModel } from '../../services/stores/api-keys';
import { withRouter } from '../../services/hooks/withRouter';

import css from './api-keys.module.scss';

const ApiKeys = () => {
	const {
		apiKeyStore: { getApiKeys, storedApiKeysList, isApiKeysLoading },
	} = useStore();
	const [update, setUpdate] = useState<boolean>(true);

	useEffect(() => {
		getApiKeys();
	}, []);

	useEffect(() => {
		if (update) {
			getApiKeys();
			setUpdate(false);
		}
	}, [update]);

	return (
		<>
			<ActionPanel
				setUpdate={setUpdate}
				onClick={() => console.info('add new Api Key')}
				buttonTitle="New Api key"
			/>
			<div className={cn('pageContent', css.apiKeys)}>
				{!isApiKeysLoading && (
					<Table<IApiKeysModel>
						className={css.table}
						size="middle"
						dataSource={storedApiKeysList}
						columns={columns}
						pagination={storedApiKeysList.length < 11 ? false : undefined}
					/>
				)}
				{isApiKeysLoading && (
					<Space size="middle">
						<Spin size="large" />
					</Space>
				)}
			</div>
		</>
	);
};

export default withRouter(observer(ApiKeys));

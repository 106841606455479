import Tabs from 'antd/es/tabs';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CustomTabs } from '../../molecules/custom-tabs';
import { HowToUseCodeBlock } from '../../molecules/how-to-use-code-block';
import { VideoButton } from '../../molecules/video-button';
import { UploadButton } from '../../molecules/upload-button';
import { useStore } from '../../../services/stores/use-store';

import { tabs } from './tabs';
import s from './how-to-use-tabs.module.scss';

interface HowToUseTabsProps {
	setActiveVideo: (value: string) => void;
	handleClick: () => void;
}

export const HowToUseTabs = ({ setActiveVideo, handleClick }: HowToUseTabsProps) => {
	const { id }: { id?: string } = useParams();
	const {
		projects: { getProjectsStat },
	} = useStore();

	const projectTabs = useMemo(() => {
		const currentProject = getProjectsStat.find((project) => project.id === id);
		return currentProject ? tabs(currentProject) : [];
	}, []);

	const [activeKey, setActiveKey] = useState<string>(() => projectTabs?.[0].key ?? '1');

	return (
		<Tabs
			activeKey={`${activeKey}`}
			type="card"
			renderTabBar={() => <CustomTabs setActiveKey={setActiveKey} activeKey={activeKey} />}
			tabPosition="left"
		>
			{projectTabs.map((tab) => {
				return (
					<Tabs.TabPane className={s.content} key={tab.key}>
						<div className={s.contentWrap}>
							<h2 className={s.title}>{tab.title}</h2>
							{tab.text1 && <div className={s.text} dangerouslySetInnerHTML={{ __html: tab.text1 }} />}
							{tab.codeBlock && <HowToUseCodeBlock content={tab.codeBlock} />}
							{tab.text2 && <div className={s.text} dangerouslySetInnerHTML={{ __html: tab.text2 }} />}
							{tab.text3 && <div className={s.text} dangerouslySetInnerHTML={{ __html: tab.text3 }} />}
							{tab.video && (
								<VideoButton
									onClick={() => {
										setActiveVideo(tab.video!);
										handleClick();
									}}
									id={activeKey}
								/>
							)}
							{tab.upload && <UploadButton />}
						</div>
					</Tabs.TabPane>
				)
			})}
		</Tabs>
	);
};

import Router from './pages/router';
import { observer } from 'mobx-react-lite';

import { withRouter } from './services/hooks/withRouter';

import css from './assets/styles/main.module.scss';

const App = () => (
	<main className={css.main}>
		<Router />
	</main>
);

export default withRouter(observer(App));

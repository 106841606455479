import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import Modal from 'antd/es/modal';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Space from 'antd/es/space';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Descriptions from 'antd/es/descriptions';
import notifications from 'antd/es/notification';

import EditSvg from '@ant-design/icons/EditOutlined';
import DeleteSvg from '@ant-design/icons/DeleteFilled';

import { IProjectsModel } from '../../../../../../services/stores/projects';
import { useStore } from '../../../../../../services/stores/use-store';

import css from './ProjectSettings.module.scss';

export type ProjectWebhookFormProps = {
  project?: IProjectsModel;
  onChange?(data: unknown): Promise<void> | void;
};

export function ProjectWebhookForm({ project }: ProjectWebhookFormProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const [form] = Form.useForm();
  const { projects } = useStore();

  const onFormSubmit = useCallback(async () => {
    if (!project) {
      return;
    }

    try {
      setIsBusy(true);
      const data = await form.validateFields();
      await projects.createWebhook(project?.id, data.url.trim());
      form.resetFields();
      setIsEdit(false);

      notifications.success({
        message: 'Webhook URL added',
      });
    } catch (e) {
      console.log(e);
      notifications.error({
        duration: 5000,
        message: `Failed to add webhook URL. Reason: ${e.body?.message}.\nDetails: ${e.body.error}`,
      });
    } finally {
      setIsBusy(false);
    }
  }, []);

  const onDeleteWebhook = useCallback(async () => {
    if (!project) {
      return;
    }

    try {
      setIsBusy(true);
      await projects.deleteWebhook(project.id);
    } catch (e) {
      notifications.error({
        duration: 5000,
        message: `Failed to delete webhook. Reason: ${e.message}.`,
      });
    } finally {
      setIsBusy(false);
    }
  }, []);

  const extra = useMemo(() => {
    return (
      <Space>
        <Tooltip title="Edit">
          <Button type="primary" icon={<EditSvg/>} onClick={() => setIsEdit(true)}/>
        </Tooltip>
        {project?.webhookUrl && (
          <Tooltip title="Delete">
            <Button loading={isBusy} type="primary" danger icon={<DeleteSvg/>} onClick={onDeleteWebhook}/>
          </Tooltip>
        )}
      </Space>
    );
  }, [project?.webhookUrl]);

  return (
    <div>
      <Descriptions
        title="Webhook settings"
        column={1}
        labelStyle={{ fontWeight: 'bold' }}
        extra={extra}
      >
        <Descriptions.Item label="Status">
          <div
            className={cn(css.status, { [css.status__error]: project?.webhookStatus !== 'OK' })}
          >{project?.webhookStatus ?? 'NOT CONFIGURED'}</div>
        </Descriptions.Item>
        {project?.webhookUrl && (
          <Descriptions.Item label="URL">
            <div className={css.value}>{project?.webhookUrl}</div>
          </Descriptions.Item>
        )}
        {project?.webhookSecret && (
          <Descriptions.Item label="Secret">
            <div className={css.value}>{project?.webhookSecret}</div>
          </Descriptions.Item>

        )}
      </Descriptions>

      <Modal
        title="Edit webhook"
        destroyOnClose={true}
        maskClosable={false}
        open={isEdit}
        onOk={onFormSubmit}
        cancelButtonProps={{ disabled: isBusy }}
        confirmLoading={isBusy}
        onCancel={() => setIsEdit(false)}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            url: project?.webhookUrl,
          }}
        >
          <Form.Item
            name="url"
            label="Webhook URL"
            rules={[{ required: true, message: 'Please enter a valid URL', type: 'url' }]}
          >
            <Input placeholder="https://example.com/api/webhook"/>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

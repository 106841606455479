import Breadcrumb from 'antd/es/breadcrumb';
import HomeIcon from '@ant-design/icons/HomeOutlined';
import { Link } from 'react-router-dom';

import css from './ProjectTitle.module.scss';

export type ProjectTitleProps = {
  title?: string;
};

export function ProjectTitle({ title }: ProjectTitleProps) {
  return (
    <Breadcrumb
      style={{
        fontSize: '20px',
      }}
      separator=">"
      items={[
        {
          title: <Link to="/projects" className={css.link}><HomeIcon aria-label="Go to projects" /></Link>,
        },
        {
          title: title,
        }
      ]}
    />
  )
  // return (
    // <div className={css.header}>
    //   <div className={css.header__wrapper}>
    //     <Link to={'/projects'}>
    //       <BackIcon className={css.header__icon}/>
    //     </Link>
    //     <Typography.Title className={css.header__title}>{title}</Typography.Title>
    //   </div>
    // </div>
  // );
}

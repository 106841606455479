export const SentFile = (
	sent: boolean,
	upload: React.MutableRefObject<HTMLInputElement | null | undefined>,
	setSent: (value: boolean) => void
) => {
	if (sent) {
		if (upload) {
			if (upload.current) {
				if (upload?.current?.files) {
					setSent(false);
				}
			}
		}
	}
};

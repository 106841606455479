import cn from 'classnames';
import Input from 'antd/es/input';
import Form from 'antd/es/form';

import { SvgIcon } from '../svg-icon';

interface CustomInputProps {
	defaultValue?: string | number;
	type?: string;
	name: string;
	value?: string;
	placeholder?: string;
	onChangeString?: (e: string) => void;
	onChangeNumber?: (e: number) => void;
	label?: string;
	inputClassName?: string;
	labelClassName?: string;
	withIcon?: boolean;
	isDisabled?: boolean;
	style?: { [key: string]: string | number };
	error?: boolean;
	errorMessage?: string;
	required?: boolean;
}
export const CustomInput = ({
	defaultValue = '',
	onChangeString,
	onChangeNumber,
	name,
	placeholder,
	inputClassName,
	labelClassName,
	label,
	value = '',
	withIcon,
	isDisabled,
	style,
	error,
	errorMessage,
	required,
	type,
}: CustomInputProps) => {
	return (
		<Form.Item name={name} rules={[{ required: true }]} style={{ ...style }}>
			<label htmlFor={name} className={labelClassName}>
				<Input
					required={required ? required : undefined}
					disabled={isDisabled}
					value={value && value?.toString().length > 0 ? value : undefined}
					defaultValue={
						defaultValue && defaultValue.toString()?.length > 0 ? defaultValue : undefined
					}
					className={cn(inputClassName, { ['ant-form-item-has-error']: error })}
					onChange={(e) => {
						if (onChangeString) {
							onChangeString(e.target.value);
						} else if (onChangeNumber) {
							onChangeNumber(+e.target.value);
						}
					}}
					type={type}
					size="large"
					autoComplete="new-password"
					placeholder={placeholder ? placeholder : undefined}
					prefix={withIcon ? <SvgIcon icon={name} fill="#141414" /> : undefined}
				/>
				{label && <span>{label}</span>}
				{error && (
					<span className="ant-form-item-explain">
						<span className="ant-form-item-explain-error">{errorMessage}</span>
					</span>
				)}
			</label>
		</Form.Item>
	);
};

import s from './dashboard-manual-step.module.scss';
import stepOne from '../../../assets/images/dashboard-step-one.jpg';
import stepTwo from '../../../assets/images/dashboard-step-two.jpg';
import stepThree from '../../../assets/images/dashboard-step-three.jpg';
const steps = [
	{
		id: 'stepOne',
		text: 'You upload data from web analytics <br /> according to an example or API doc',
		image: stepOne,
	},
	{
		id: 'stepTwo',
		text: 'We analyze data, building the customer <br /> journey and discovering the issues that customers face',
		image: stepTwo,
	},
	{
		id: 'stepThree',
		text: 'You receive the customer journey<br /> per touchpoint with bottlenecks,<br /> repetitive actions, outliers<br /> and metrics per step',
		image: stepThree,
	},
];
export const DashboardManualStep = () => (
	<div className={s.manual}>
		{steps.map(({ id, image, text }) => (
			<div className={s.item} key={id}>
				<img src={image} alt={id} />
				<div className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
			</div>
		))}
	</div>
);

import React from 'react';

import Header from '../../organisms/header';

import css from './layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
  pageTitle: string;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Header className={css.header}/>
      <div className={css.content}>
        {children}
      </div>
    </>
  );
};

import { getParent, Instance, cast, flow } from 'mobx-state-tree';
import { types as t } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { localStorageCheck } from '../common/local-storage-check';
import { requests } from '../api/api';
import { setToLocalStorage } from '../common/set-to-local-storage';

const IntegrationsModel = t.model('IntegrationsModel', {
  id: t.string,
  name: t.string,
  provider: t.string,
  data: t.frozen(),
});

const ProjectsModel = t.model('ProjectsModel', {
  id: t.string,
  accountId: t.number,
  name: t.string,
  createdAt: t.string,
  updatedAt: t.string,
  cases: t.maybeNull(t.number),
  nodes: t.maybeNull(t.number),
  problems: t.maybeNull(t.number),
  bottleNecks: t.maybeNull(t.number),
  average: t.maybeNull(t.number),
  apiToken: t.maybeNull(t.string),
  user: t.maybeNull(t.string),
  webhookUrl: t.maybeNull(t.string),
  webhookStatus: t.maybeNull(t.string),
  webhookSecret: t.maybeNull(t.string),
  integrations: t.array(IntegrationsModel),
});

export type ProjectIntegrationsModel = Instance<typeof IntegrationsModel>;

export type IProjectsModel = Instance<typeof ProjectsModel>;

const ProjectsStore = t
  .model('Projects', {
    isProjectsLoading: t.boolean,
    activeProjectId: t.maybeNull(t.string),
    projects: t.array(ProjectsModel),
    lastOpened: t.array(t.string),
    sortBy: t.string,
    filteredBy: t.string,
  })
  .views((self) => ({
    get mobxStore() {
      return getParent(self);
    },
    get getSortBy() {
      return self.sortBy;
    },
    get getFilteredBy() {
      return self.filteredBy;
    },
    get getLastOpened() {
      const lastOpenedIds = toJS(self.lastOpened);
      return toJS(self.projects).filter((p) => lastOpenedIds.includes(p.id));
    },
    get getActiveProjectId() {
      return toJS(self.activeProjectId);
    },
    get getProjectsStat() {
      return toJS(self.projects);
    },
    get getProjectsLength() {
      let result = 0;
      if (self.projects) {
        result = toJS(self.projects.length);
      }
      return result;
    },
  }))
  .actions((self) => {
    const setIsProjectsLoading = (status: boolean) => {
      self.isProjectsLoading = status;
    };
    const setSortBy = (sortBy: string) => {
      self.sortBy = sortBy;
    };
    const setFilteredBy = (filteredBy: string) => {
      self.filteredBy = filteredBy;
    };
    const setIsActiveProject = (id: string | null) => {
      self.activeProjectId = id;
      if (id === null) {
        return;
      }

      const isLastOpened = self.lastOpened.includes(id);
      if (!isLastOpened) {
        self.lastOpened.push(id);
        setToLocalStorage('lastOpened', JSON.stringify(toJS(self.lastOpened)));
      }
    };
    const setProjects = (projects: IProjectsModel[]) => {
      self.projects = cast(projects);
    };

    const closeProject = (id: string) => {
      if (localStorageCheck()) {
        const lastOpened = self.lastOpened.filter((p) => p === id);
        self.lastOpened.remove(id);
        setToLocalStorage('lastOpened', JSON.stringify(toJS(lastOpened)));
      }
    };

    const getProjects = flow(function* () {
      setIsProjectsLoading(true);
      try {
        let order = '';
        if (self.sortBy === 'Recent') {
          order = 'updated_at';
        } else if (self.sortBy === 'Alphabetical') {
          order = 'name';
        }

        const response = yield requests.getProjects(order, self.filteredBy);

        type ProjectsResponse = (IProjectsModel & { account?: { users: { email: string, created_at: string }[] } })[]
        const projects = response.data as ProjectsResponse;

        setProjects(projects.map((p) => ({
          ...p,
          user: p.account?.users
            ?.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
            ?.[0].email || null,
        })));

        return { status: 200 };
      } catch (e) {
        const error = e as Error & { status?: number };
        console.error('Failed to load projects: ', error.message);
        console.error(error);

        if (error.status === 401) {
          window.location.href = '/auth';
        }

        return { status: 500 };
      } finally {
        setIsProjectsLoading(false);
      }
    });

    const deleteProject = flow(function* (id: string) {
      setIsProjectsLoading(true);
      try {
        yield requests.deleteProject(id);
        return { status: 200 };
      } catch (error) {
        return { status: 500 };
      } finally {
        setIsProjectsLoading(false);
      }
    });

    const createProject = flow(function* (data, callback) {
      setIsProjectsLoading(true);
      try {
        const { data: project } = yield requests.createProject(data);
        yield getProjects();
        callback(project.id);
        return { status: 200 };
      } catch (error) {
        return { status: 500 };
      } finally {
        setIsProjectsLoading(false);
      }
    });

    const updateProject = flow(function* (id, data) {
      setIsProjectsLoading(true);
      try {
        yield requests.updateProject(id, data);
        return { status: 200 };
      } finally {
        setIsProjectsLoading(false);
      }
    });

    const setToken = flow(function* (id: string) {
      setIsProjectsLoading(true);
      try {
        yield requests.setToken(id);
        return { status: 200 };
      } catch (error) {
        return { status: 500 };
      } finally {
        setIsProjectsLoading(false);
      }
    });

    const dropToken = flow(function* (id: string) {
      setIsProjectsLoading(true);
      try {
        yield requests.dropToken(id);
        return { status: 200 };
      } catch (error) {
        return { status: 500 };
      } finally {
        setIsProjectsLoading(false);
      }
    });

    const createWebhook = flow(function* (projectId: string, url: string) {
      const { data } = yield requests.createWebhook(projectId, url);
      const project = self.projects.find((p) => p.id === projectId);
      if (project) {
        console.log(project, 'asdasd');
        project.webhookSecret = data.secret;
        project.webhookStatus = 'OK';
        project.webhookUrl = data.url;
        console.log(project, 'asdasdasdasd');
      }
    });

    const deleteWebhook = flow(function* (projectId: string) {
      yield requests.deleteWebhook(projectId);
      const project = self.projects.find((p) => p.id === projectId);
      if (project) {
        project.webhookSecret = null;
        project.webhookStatus = null;
        project.webhookUrl = null;
        console.log(project, 'asdasdasdasd');
      }
    });

    const createIntegration = flow(function* (projectId: string, provider: string, name: string, data: Record<string, unknown>) {
      const { data: integration } = yield requests.createIntegration(projectId, name, provider, data);
      const project = self.projects.find((p) => p.id === projectId);
      if (project) {
        project.integrations.push(integration);
      }
    });

    const updateIntegration = flow(function* (projectId: string, integrationId: string, name: string, data: Record<string, unknown>) {
      yield requests.updateIntegration(projectId, integrationId, name, data);
      const project = self.projects.find((p) => p.id === projectId);
      if (project) {
        const integrationIndex = project.integrations.findIndex((i) => i.id === integrationId);
        const updatingIntegration = project.integrations[integrationIndex];
        project.integrations[integrationIndex] = {
          ...updatingIntegration,
          name,
          data,
        };
      }
    });

    const deleteIntegration = flow(function* (projectId: string, integrationId: string) {
      const project = self.projects.find((p) => p.id === projectId);
      if (!project) {
        return;
      }

      const integration = project.integrations.find((i) => i.id === integrationId);
      if (!integration) {
        return;
      }

      yield requests.deleteIntegration(projectId, integrationId);

      project.integrations.remove(integration);
    });

    return {
      createProject, updateProject, deleteProject,
      setIsActiveProject, setProjects, setToken, dropToken,
      closeProject, getProjects, setFilteredBy, setSortBy,
      createWebhook, deleteWebhook,
      createIntegration, updateIntegration, deleteIntegration,
    };
  });

export default ProjectsStore;
export type IProjectsStore = Instance<typeof ProjectsStore>;

import { DownOutlined } from '@ant-design/icons';
import Dropdown from 'antd/es/dropdown';

import { DropdownMenuItem } from '../dropdown-menu-item';

interface DropdownSelectorProps {
	onSelect: (value: string) => void;
	setUpdate?: (value: boolean) => void;
	selectedValue: string;
	items: string[];
}

export const DropdownSelector = ({
	onSelect,
	selectedValue,
	setUpdate,
	items,
}: DropdownSelectorProps) => {
	return (
		<Dropdown
			overlay={
				<DropdownMenuItem
					items={items}
					onSelect={(value) => {
						onSelect(value);
						if (setUpdate) {
							setUpdate(true);
						}
					}}
				/>
			}
			trigger={['click']}
			placement="bottomRight"
		>
			<span>
				{selectedValue} <DownOutlined />
			</span>
		</Dropdown>
	);
};

import { useEffect, useState, useRef } from 'react';
import Spin from 'antd/es/spin';

import nodeStart from '../../../assets/images/node-start.svg';
import nodeFinish from '../../../assets/images/node-finish.svg';
import nodeCases from '../../../assets/images/node-regular.svg';
import nodeProblem from '../../../assets/images/problem.svg';
import nodeProblemLoop from '../../../assets/images/problem-loop.svg';
import nodeNodes from '../../../assets/images/node-regular.svg';
import nodeBottleneck from '../../../assets/images/node-bottleneck.svg';
import nodeHidden from '../../../assets/images/node-regular.svg';
import nodeMassDrop from '../../../assets/images/node-massdrop.svg';
import nodeWaterfall from '../../../assets/images/node-waterfall.svg';
import nodeButton from '../../../assets/images/node-button.svg';
import nodeLight from '../../../assets/images/node-flashlight.svg';

import { FullscreenButton } from '../../atoms/fullscreen-button';
import { DropdownSelector } from '../../atoms/dropdown-selector';
import { GraphData } from '../../../types/interface/graphData';

import { graphUpdate, graphClear, fullscreenToggle } from './handlers';
import css from './graph.module.scss';

const LAYOUTS = [
  { key: 'power', value: 'Power layout' },
  { key: 'tree', value: 'Tree layout' },
];

const GRAPH_OPTIONS = {
  forceCharge: -3000,
  forceHeight: 400,
  forceWidth: 400,
  height: '100%',
  layout: 'TREE',
  levelGap: 300,
  mainPath: false,
  mainPathLeftPadding: 350,
  width: '100vw',
};

const Graph = ({ graphData, loading }: { graphData?: GraphData | null, loading?: boolean }) => {
  const [forceLink, setForceLink] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [layout, setLayout] = useState(LAYOUTS[0].key);
  const [userParams] = useState({
    nodeFreqFilter: 0,
    switchShowEdgeTitle: true,
    switchShowHiddenItems: true,
    switchUseSmartFilter: true,
  });

  const graphRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { height, width } = GRAPH_OPTIONS;

  useEffect(() => {
    if (isInit) {
      graphClear(forceLink);
    }

    if (!loading && graphData) {
      graphUpdate({ options: GRAPH_OPTIONS, graphData, userParams, setForceLink, graphRef, graphLayout: layout, classes: css });
      setIsInit(true);
    }
  }, [graphData, layout, loading]);

  return (
    <div className={css.graph}>
      <div className={css.titleBox}>
        {/*<div className={s.title}>{graphData?.name}, average ticket size {graphData?.average} $</div>*/}
        <DropdownSelector
          selectedValue={LAYOUTS.find((item) => item.key === layout)?.value || LAYOUTS[0].value}
          onSelect={(value: string) => setLayout(LAYOUTS.find((item) => item.value === value)?.key || LAYOUTS[0].key)}
          items={LAYOUTS.map((item) => item.value)}
        />
      </div>
      <div className={css.graphWrap}>
        {loading ? <Spin size="large"/> : (
          <div ref={graphRef} className={css.graphField}>
            <FullscreenButton graphRef={graphRef} onClick={fullscreenToggle}/>
            <div id="tooltip" className={css.tooltip}/>
            <svg className="cabSvg" width={width} height={height}>
              <defs>
                <filter id="nodeCases" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlinkHref={nodeCases}/>
                </filter>
                <filter id="nodeStart" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlinkHref={nodeStart}/>
                </filter>
                <filter id="nodeFinish" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlinkHref={nodeFinish}/>
                </filter>
                <filter id="nodeProblem" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlinkHref={nodeProblem}/>
                </filter>
                <filter id="nodeProblemLoop" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlinkHref={nodeProblemLoop}/>
                </filter>
                <filter id="nodeNodes" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlinkHref={nodeNodes}/>
                </filter>
                <filter id="nodeMassDrop" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlinkHref={nodeMassDrop}/>
                </filter>
                <filter id="nodeWaterfall" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlinkHref={nodeWaterfall}/>
                </filter>
                <filter id="nodeButton" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlinkHref={nodeButton}/>
                </filter>
                <filter id="nodeLight" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlinkHref={nodeLight}/>
                </filter>
                <filter
                  id="nodeBottleneck"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                >
                  <feImage xlinkHref={nodeBottleneck}/>
                </filter>
                <filter id="nodeHidden" x="15%" y="15%" width="60%" height="60%">
                  <feImage xlinkHref={nodeHidden}/>
                </filter>
              </defs>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};
export default Graph;

import { useEffect, useState } from 'react';
import notifications from 'antd/es/notification';

import { request } from '../../../services/api/api';
import { GraphData } from '../../../types/interface/graphData';

export type GraphDataState = {
  graphData: GraphData | null;
  isLoading: boolean;
}

export function useGraphData(graphId?: string) {
  const [state, setState] = useState<GraphDataState>({ isLoading: true, graphData: null });

  useEffect(() => {
    let mounted = true;
    if (!graphId) {
      return ;
    }

    setState((prev) => ({ ...prev, isLoading: true }));

    request<{url: string}>({ route: `/api/projects/url/${graphId}` })
      // @ts-ignore
      .then((res) => {
        if (res.status !== 200) {
          return res;
        }

        return fetch(res.data.url).then((r) => {
          if (r.status !== 200) {
            return Promise.reject({
              status: r.status,
              message: r.statusText,
            });
          }

          return r.json() as Promise<{ data: GraphData }>;
        })
      })
      .then((data) => ({ status: 200, data }))
      .then((res) => {
        if (!mounted) {
          return;
        }

        if (res.status !== 200) {
          setState({ isLoading: false, graphData: null });
          console.error('Failed to load graph: ');
          console.error(res);
          return;
        }

        const data = res.data as unknown as GraphData;
        (window as any).__GRAPH_DATA__ = data;

        setState({ graphData: data, isLoading: false })
      })
      .catch((e) => {
        if (mounted) {
          setState({ isLoading: false, graphData: null });
        }

        if (e.status === 404) {
          return;
        }

        notifications.error({
          message: 'Error',
          description: `Something went wrong while loading graph data: ${e.message}`,
        });
      });

    return () => {
      mounted = false;
    }
  }, [graphId]);

  return state;
}

import { useCallback } from 'react';

import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';

import { IProjectsModel } from '../../../../../../services/stores/projects';

export type ProjectInfoFormProps = {
  onChange?(data: unknown): Promise<void> | void;
  project?: IProjectsModel;
};

export function ProjectInfoForm({ onChange, project }: ProjectInfoFormProps) {
  const [form] = Form.useForm();

  const onFormChange = useCallback(async () => {
    try {
      const data = await form.validateFields();
      await onChange?.(data);

    } catch (e) {
      console.warn(e);
    }
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      name="projectInfo"
      autoComplete="off"
      onChange={onFormChange}
      initialValues={{
        name: project?.name,
        ticketSize: project?.average ?? 0,
      }}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please enter project name', type: 'string' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="ticketSize"
        label="Average Ticket Size"
        rules={[{ required: true, message: 'Please enter value', type: 'number' }]}>
        <InputNumber style={{width: '100%'}} />
      </Form.Item>
    </Form>
  );
}

import { useParams } from 'react-router-dom';
import Spin from 'antd/es/spin';
import { observer } from 'mobx-react-lite';

import { ProjectItem } from '../../../../compoments/organisms/project-item';
import { withRouter } from '../../../../services/hooks/withRouter';

import { useGraphData } from '../../hooks/useGraphData';

import css from './ProjectGraph.module.scss';
import { useStore } from '../../../../services/stores/use-store';
import { useMemo } from 'react';
import { ProjectTitle } from '../ProjectTitle/ProjectTitle';

function ProjectGraph() {
  const { id } = useParams();
  const { projects } = useStore();
  const { graphData, isLoading } = useGraphData(id);

  const currentProject = useMemo(() => {
    return projects.getProjectsStat.find((p) => p.id === id);
  }, [id, projects.getProjectsStat]);

  return (
    <div className={css.wrapper}>
      <ProjectTitle title={currentProject?.name}/>
      <div className={css.project}>
        {isLoading && (
          <div className={css.spin}>
            <Spin size="large"/>
          </div>
        )}
        {graphData && <ProjectItem graphData={graphData} loading={isLoading}/>}
      </div>
    </div>
  );
}

export default withRouter(observer(ProjectGraph));

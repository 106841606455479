import { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'antd/es/dropdown';
import notification from 'antd/es/notification';

import dayjs from 'dayjs';

import { SvgIcon } from '../svg-icon';
import { useStore } from '../../../services/stores/use-store';
import { EditProjectMenu } from '../../molecules/edit-project-menu';

import { ProjectForm } from '../../../pages/projects/components/ProjectForm';
import { IProjectsModel } from '../../../services/stores/projects';

import css from './statistic-info.module.scss';

interface StatisticInfoProps {
  project: IProjectsModel;
}

export const StatisticInfo = ({ project }: StatisticInfoProps) => {
  const { id, updatedAt, apiToken } = project;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { projects } = useStore();

  const onSetToken = async () => {
    await projects.setToken(id);
    await projects.getProjects();
  };

  const onCopyToken = async () => {
    try {
      if (apiToken) {
        await navigator.clipboard.writeText(apiToken);
        notification.success({
          message: 'Success',
          description: 'API token copied to clipboard.',
          duration: 3,
        });
      }
    } catch (e) {
      const error = e as Error;
      console.error('Something went wrong while copying api token: ', error?.message);
    }
  };

  return (
    <div className={css.info}>
      <Link to={`/project/${id}`} className={css.text}>
        <div>
          <p className={css.panelTitle}>{project.user ? `${project.name} (${project.user})` : project.name}</p>
          <p className={css.panelInfo}>{`Edited ${dayjs(updatedAt).format('DD MMM YYYY HH:mm')}`}</p>
          {apiToken && <p className={css.panelInfo}>{`API token ${apiToken}`}</p>}
        </div>
      </Link>
      <Dropdown
        overlay={(
          <EditProjectMenu
            onEdit={() => setIsModalOpen(true)}
            onSetToken={onSetToken}
            onCopyToken={onCopyToken}
            hasToken={Boolean(apiToken)}
          />
        )}
        overlayClassName={css.overlay}
        placement="bottomRight"
        trigger={['click']}
      >
				<span className={css.moreIcon}>
					<SvgIcon icon="more"/>
				</span>
      </Dropdown>
      <ProjectForm project={project} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}/>
    </div>
  );
};

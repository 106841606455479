import { types as t, cast, flow, Instance } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { requests } from '../api/api';

const TeamListModel = t.model('TeamListModel', {
  id: t.string,
  email: t.string,
  name: t.maybeNull(t.string),
  createdAt: t.string,
  updatedAt: t.string,
});

export type ITeamListModel = Instance<typeof TeamListModel>;

const TeamStore = t
  .model('Team', {
    isTeamLoading: t.boolean,
    membersList: t.array(TeamListModel),
  })
  .views((self) => ({
    get members() {
      return toJS(self.membersList);
    },
  }))
  .actions((self) => {
    const setIsTeamLoading = (status: boolean) => {
      self.isTeamLoading = status;
    };
    const setTeamList = (items: ITeamListModel[]) => {
      self.membersList = cast(items);
    };

    const getTeamList = flow(function* () {
      setIsTeamLoading(true);
      try {
        const { data: teamList } = yield requests.getTeam();
        setTeamList(teamList);
        return { status: 200 };
      } catch (error) {
        return { status: 500 };
      } finally {
        setIsTeamLoading(false);
      }
    });

    const updateProfile = flow(function* ({ name }) {
      setIsTeamLoading(true);
      try {
        yield requests.updateProfile({ name });
        return { status: 200 };
      } catch (error) {
        return { status: 500 };
      } finally {
        setIsTeamLoading(false);
      }
    });

    const sendInvite = flow(function* (email: string) {
      const teamList = yield requests.inviteUser(email);
      if (teamList.status == 200) {
        getTeamList();
      }
      return { status: 200 };

    });

    const removeMember = flow(function* (email) {
      yield requests.removeMember(email)
      yield getTeamList();
    });

    return {
      setIsTeamLoading,
      setTeamList,
      getTeamList,
      sendInvite,
      removeMember,
      updateProfile,
    };
  });

export default TeamStore;
export type ITeamStore = Instance<typeof TeamStore>;

import { useCallback, useState } from 'react';

import { ProjectIntegrationsModel } from '../../../../../../services/stores/projects';
import Form from 'antd/es/form';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import { useStore } from '../../../../../../services/stores/use-store';

export type AddOrEditIntegrationModalProps = {
  integration?: ProjectIntegrationsModel;
  isOpen: boolean;
  onClose(): void;
  projectId: string;
};

export function AddOrEditIntegrationModal({ projectId, integration, isOpen, onClose }: AddOrEditIntegrationModalProps) {
  const [isBusy, setIsBusy] = useState(false);
  const [form] = Form.useForm();

  const { projects } = useStore();

  const onSaveClick = useCallback(async () => {
    setIsBusy(true);
    try {
      const data = await form.validateFields();

      if (!integration) {
        await projects.createIntegration(projectId, 'convertflow', data.name.trim(), { ctaId: data.cfCtaId });
      } else {
        await projects.updateIntegration(projectId, integration.id, data.name.trim(), { ctaId: data.cfCtaId });
      }

      onClose();
    } catch (e) {
      console.warn(e);
    } finally {
      setIsBusy(false);
    }
  }, [integration]);

  return (
    <Modal
      open={isOpen}
      title={integration ? 'Edit integration' : 'Create integration'}
      confirmLoading={isBusy}
      onOk={onSaveClick}
      okText="Save"
      onCancel={onClose}
    >
      <Form
        form={form}
        name="projectIntegration"
        autoComplete="off"
        initialValues={{
          name: integration?.name,
          cfCtaId: integration?.data?.ctaId,
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input integration name', type: 'string' }]}
        >
          <Input
            placeholder="Integration Name"
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          name="cfCtaId"
          label="CTA ID"
          rules={[{ required: true, message: 'Please input Convertflow CTA ID' }]}
        >
          <InputNumber
            placeholder="ConvertFlow Popup CTA ID"
            controls={false}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

import Pie from '@ant-design/plots/es/components/pie';

export type DonutProps = {
  data: Record<string, unknown>[];
  angleField: string;
  colorField: string;
};

export function Donut({ data, angleField, colorField }: DonutProps) {
  return (
    <Pie angleField={angleField} colorField={colorField} data={data} radius={1} innerRadius={0.6} />
  )
}

import { useEffect } from 'react';
import s from './confirmation.module.scss';
import confirmationIcon from '../../../assets/images/success.jpg';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../services/stores/use-store';
import { withRouter } from '../../../services/hooks/withRouter';

const Confirmation = () => {
	const navigate = useNavigate();
	const { authStore } = useStore();
	const { confirmationText } = authStore;
	useEffect(() => {
		const redirect = function goToConfirm() {
			navigate('/projects');
		};
		const redirectTimeOut: NodeJS.Timeout = setTimeout(redirect, 500);
		return () => clearTimeout(redirectTimeOut);
	}, []);
	return (
		<div className={s.confirmation}>
			<img src={confirmationIcon} alt="confirmation" />
			<p className={s.text}>{confirmationText}</p>
		</div>
	);
};

export default withRouter(observer(Confirmation));

import React from 'react';
import Button, { ButtonShape, ButtonType, ButtonSize } from 'antd/es/button';

import { SvgIcon } from '../svg-icon';

interface CustomButtonProps {
	size?: ButtonSize;
	type?: ButtonType;
	text?: string;
	className?: string;
	onClick?: React.MouseEventHandler<HTMLElement>;
	withIcon?: boolean;
	isDisabled?: boolean;
	icon?: string;
	fill?: string;
	shape?: ButtonShape;
	isLoading?: boolean;
	image?: JSX.Element;
}
export const CustomButton = ({
	text,
	onClick,
	className,
	withIcon,
	icon,
	isDisabled,
	shape,
	fill,
	type = 'text',
	size = 'large',
	image,
}: CustomButtonProps) => (
	<Button
		shape={shape ? shape : undefined}
		disabled={isDisabled}
		size={size}
		type={type}
		onClick={onClick}
		className={className ? className : ''}
		icon={withIcon ? <SvgIcon icon={icon} fill={fill} /> : undefined}
	>
		{image ? image : ''}
		{text ? text : ''}
	</Button>
);

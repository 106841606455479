import { flow, types as t } from 'mobx-state-tree';
import { requests } from '../api/api';

type SignInPayload = {
  email: string;
  password: string;
  remember: boolean;
}

const UserModel = t.model('UserModel', {
  id: t.string,
  name: t.string,
  email: t.string,
  accountId: t.number,
});

const AuthStore = t
  .model('Auth', {
    user: t.maybeNull(UserModel),
    isAuthenticated: t.boolean,
    isAuthLoading: t.boolean,
    isAlone: t.boolean,
    isRemember: t.boolean,
    authState: t.string,
    enterToken: t.string,
    confirmationText: t.string,
  })
  .actions((self) => {
    const setAuthState = (state: string) => {
      self.authState = state;
    };
    const setEnterToken = (token: string) => {
      self.enterToken = token;
    };
    const setConfirmationText = (text: string) => {
      self.confirmationText = text;
    };
    const setIsRemember = (remember: boolean) => {
      self.isRemember = remember;
    };
    const setIsAlone = (alone: boolean) => {
      self.isAlone = alone;
    };

    const checkAuth = flow(function* () {
      try {
        const { data } = yield requests.getProfile();

        self.isAuthenticated = true;
        self.user = {
          id: data.id,
          name: data.name,
          email: data.email,
          accountId: data.accountId,
        };
      } catch (e) {
      } finally {
        self.isAuthLoading = false;
      }
    });

    const letSignIn = flow(function* (options: SignInPayload) {
      const { email, password, remember } = options;
      try {
        const response = yield requests.signIn(email, password, remember);

        if (response.status === 200) {
          const user = response.data;
          self.isAuthenticated = true;
          self.isAuthLoading = false;
          self.user = {
            id: user.id,
            name: user.name,
            email: user.email,
            accountId: user.accountId,
          };

          return { status: 200, message: response.message };
        }

        if ([401, 500].includes(response.status)) {
          return { status: 500, message: 'check your e-mail or password' };
        }

        return response;
      } catch (error) {
        console.error(error);
        return { status: 500, message: error.message };
      }
    });

    const letSignUp = flow(function* (email: string, reset?: boolean) {
      try {
        const signIn = yield requests.signUp(email, reset);
        return { status: signIn.status, message: signIn.message };
      } catch (error) {
        return { status: 500, message: error.message };
      }
    });

    const letEnterPassword = flow(function* (enterToken: string, password: string, idToken?: string) {
      try {
        const enterPassword = yield requests.enterPassword(enterToken, password, idToken);
        return { status: enterPassword.status, message: enterPassword.message };
      } catch (error) {
        return { status: 500, message: error.message };
      }
    });

    const letLogout = flow(function* () {
      try {
        return yield requests.logout();
      } catch (error) {
        return { status: 500, message: error.message };
      }
    });

    return {
      setAuthState,
      setEnterToken,
      setConfirmationText,
      setIsAlone,
      setIsRemember,
      checkAuth,
      letSignIn,
      letSignUp,
      letEnterPassword,
      letLogout,
    };
  });

export default AuthStore;

import { useEffect, useReducer } from 'react';
import notification from 'antd/es/notification';
import { request } from '../../../../../services/api/api';
import { IProjectsModel } from '../../../../../services/stores/projects';
import { GraphData } from '../../../../../types/interface/graphData';

export type DashboardResponse = {
  node_loss_money: Record<string, number>; // +
  statistics_of_variants: {
    count: number; // X Просуммировать по deep_number
    deep_number: number; // Y, не уникальные
    trace: string[]; // Размер бублика - количество вариантов
    // TODO промапить данные статистики
  }[];
  top_3_problem_variants: Top3ProblemVariants[];
  top_5_bad_nodes: Record<string, {
    frequency: number | string;
    loss_money: number | string;
  }>
};

export type Top3ProblemVariants = {
  deep_number: number;
  count: number;
  nodes: Top3ProblemNode[];
  trace: string[];
};

export type Top3ProblemNode = {
  id: string;
  label: string;
  loss_number: number;
  visible: true;
  metrics: {
    conversion: number;
    frequency: string;
  };
}

type DashboardValue = {
  name: string;
  value: number;
}

export type DashboardData = {
  moneyLoss: DashboardValue[];
  top5BadNodes: (DashboardValue & { type: string })[];
  top3ProblemVariants: (DashboardValue & { type: string; children: DashboardValue[] })[];
  statisticOfVariants: {
    count: number; // X
    deepNumber: number; // Y
    countSum: number;
    trace: string[];
  }[];
}

export type DashboardState = {
  isLoading?: boolean;
  dashboardData: DashboardData | null;
}

export type DashboardAction = {
  type: string;
  payload: Partial<DashboardState>;
}

function reducer(state: DashboardState, action: DashboardAction): DashboardState {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case 'SET_DATA':
      return {
        ...state,
        dashboardData: action.payload.dashboardData ?? null,
        isLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
}

const INITIAL_STATE: DashboardData = {
  moneyLoss: [],
  top5BadNodes: [],
  top3ProblemVariants: [],
  statisticOfVariants: [],
};

export function useDashboardData(project?: IProjectsModel) {
  const [state, dispatch] = useReducer(reducer, { isLoading: true, dashboardData: INITIAL_STATE });

  useEffect(() => {
    let mounted = true;
    if (!project?.id) {
      return;
    }

    request<{ url: string }>({ route: `/api/projects/url/${project.id}/dashboard` })
      // @ts-ignore
      .then((res) => {
        if (res.status !== 200) {
          return res;
        }

        return fetch(res.data.url).then((r) => {
          if (r.status !== 200) {
            return Promise.reject({
              status: r.status,
              message: r.statusText,
            });
          }

          return r.json() as Promise<{ data: GraphData }>;
        });
      })
      .then((data) => ({ status: 200, data }))
      // @ts-ignore
      .then(({ status, data }: { status: number, data: DashboardResponse }) => {
        if (status !== 200 || !mounted) {
          return;
        }

        (window as any).__DASHBOARD_DATA__ = data;

        const moneyLoss: DashboardData['moneyLoss'] = Object
          .entries(data.node_loss_money)
          .filter(([, value]) => value > 0)
          .slice(0, 15)
          .map(([name, value]) => ({
            name,
            value: Math.round(value),
          }));

        if (moneyLoss.length === 0) {
          moneyLoss.push({
            name: 'Average ticket size is not found',
            value: 0,
          });
        }

        const dashboardData: DashboardData = {
          moneyLoss,
          top5BadNodes: getTop5BadNodes(data),
          top3ProblemVariants: getTop3ProblemVariants(data),
          statisticOfVariants: getStatisticOfVariants(data),
        };

        dispatch({ type: 'SET_DATA', payload: { isLoading: false, dashboardData } });

        (window as any).__DASHBOARD_DATA__ = dashboardData;
      })
      .catch((e) => {
        console.error('Failed to load dashboard data', e);
        if (!mounted) {
          return;
        }

        if (e.status === 404) {
          dispatch({ type: 'SET_DATA', payload: { isLoading: false, dashboardData: null } });

          return;
        }

        notification.error({
          message: 'Error',
          description: `Something went wrong while loading dashboard data: ${e?.status}`,
        });

        dispatch({ type: 'SET_LOADING', payload: { isLoading: false, dashboardData: INITIAL_STATE } });
      });

    return function cleanup() {
      mounted = false;
    };
  }, [project?.id]);

  return state;
}

function getTop5BadNodes(data: DashboardResponse): DashboardData['top5BadNodes'] {
  return Object.entries(data.top_5_bad_nodes)
    .reduce<DashboardData['top5BadNodes']>((acc, [type, data]) => {
      const { frequency, loss_money } = data;
      const freq = typeof frequency === 'number' ? frequency : parseInt(frequency, 10);
      const loss = typeof loss_money === 'number' ? loss_money : parseInt(loss_money, 10);

      acc.push({ type, name: 'Frequency', value: Math.round(freq) });
      acc.push({ type, name: 'Loss', value: Math.round(loss) });

      return acc;
    }, []);
}

function getTop3ProblemVariants(data: DashboardResponse):DashboardData['top3ProblemVariants'] {
  try {
    return data.top_3_problem_variants
      .reduce<DashboardData['top3ProblemVariants']>((acc, cur, i) => {
        const trace = getVariantTrace(cur.trace);
        const children = cur.nodes.map((node, i) => {
          return {
            name: node.label,
            index: i,
            trace,
            frequency: parseInt(node.metrics.frequency, 10),
            conversion: Math.round(node.metrics.conversion * 100) / 100,
            value: Math.round(node.loss_number * cur.count * 100) / 100,
          }
        })

        acc.push({
          name: `Variant ${i} (depth: ${cur.deep_number})`,
          type: `Variant ${i} (depth: ${cur.deep_number})`,
          value: cur.count,
          children,
        });

        return acc;
      }, []);
  } catch (e) {
    console.error('Failed to parse top3ProblemVariants', e);
    return [];
  }
}

function getVariantTrace(trace: string[]): string {
  return collapseTrace(trace);
}

function collapseTrace(trace: string[]): string {
  const collapsedTraceMap = new Map<string, number>();

  for (const traceItem of trace) {
    const count = collapsedTraceMap.get(traceItem) ?? 0;
    collapsedTraceMap.set(traceItem, count + 1);
  }

  return Array.from(collapsedTraceMap.entries()).reduce((acc, [traceItem, count]) => {
    const currentTrace = count > 1 ? `${traceItem} (${count})` : traceItem;
    if (acc.length === 0) {
      return currentTrace;
    }

    return `${acc} > ${currentTrace}`;
  }, '');
}

function getStatisticOfVariants(data: DashboardResponse): DashboardData['statisticOfVariants']{
  try {
    return data.statistics_of_variants
      .reduce<DashboardData['statisticOfVariants']>((acc, current) => {
        const existed = acc.find((v) => v.count === current.count);
        if (existed) {
          existed.count += current.deep_number;
          existed.countSum += 1;
          return acc;
        }

        acc.push({
          countSum: 1,
          count: current.count,
          deepNumber: current.deep_number,
          trace: current.trace,
        });

        return acc;
      }, []);
  } catch (e) {
    console.error('Failed to parse statisticOfVariants', e);
    return [];
  }
}

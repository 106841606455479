import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import SettingOutlined from '@ant-design/icons/SettingOutlined';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Spin from 'antd/es/spin';
import Button from 'antd/es/button';

import { withRouter } from '../../../../services/hooks/withRouter';
import { useStore } from '../../../../services/stores/use-store';

import { useDashboardData } from './hooks/useDashboardData';
import { useGraphData } from '../../hooks/useGraphData';
import { ProjectTable } from '../../../../compoments/atoms/project-table';
import { ProjectStats } from './components/ProjectStats/ProjectStats';
import { ProjectSettings } from './components/ProjectSettings/ProjectSettings';
import { ErrorBoundary } from '../../../../compoments/ErrorBoundary';
import { ProjectManual } from '../../../../compoments/molecules/project-manual';
import { ProjectTitle } from '../ProjectTitle/ProjectTitle';

import {
  Donut,
  ColumnChart,
  TreeMap,
  BubbleChart,
} from './components';

import css from './ProjectDashboard.module.scss';

function ProjectDashboard() {
  const { id } = useParams();
  const { projects } = useStore();
  const [isSettingsShown, setIsSettingsShown] = useState(false);

  const currentProject = useMemo(() => {
    return projects.getProjectsStat.find((p) => p.id === id);
  }, [id, projects.getProjectsStat]);

  const { isLoading, dashboardData } = useDashboardData(currentProject);
  const { isLoading: isGraphLoading, graphData } = useGraphData(currentProject?.id);

  if (!currentProject) {
    return null;
  }

  if (isLoading) {
    return (
      <div className={cn(css.container, css.container__full)}>
        <div className={css.loader}>
          <Spin size="large"/>
        </div>
      </div>
    );
  }

  if (dashboardData === null) {
    return (
      <div className={css.container}>
        <div className={css.header}>
          <ProjectTitle title={currentProject?.name} />
          <div className={css.header__actions}>
            <Button
              type="default"
              icon={<SettingOutlined/>}
              onClick={() => setIsSettingsShown(true)}
            >
              Settings
            </Button>
          </div>
        </div>
        <Row gutter={[64, 64]} className={css.content}>
          <Col span={24}>
            <ProjectManual/>
          </Col>
        </Row>
        {isSettingsShown && (
          <ProjectSettings
            open={isSettingsShown}
            project={currentProject}
            onClose={() => setIsSettingsShown(false)}
          />
        )}
      </div>
    );
  }

  return (
    <div className={css.container}>
      <div className={css.header}>
        <ProjectTitle title={currentProject?.name} />
        <div className={css.header__actions}>
          {isGraphLoading && (
            <Spin size="default"/>
          )}
          {!isGraphLoading && graphData && <ProjectStats data={graphData}/>}
          <Button type="primary" href={`/project/${id}/graph`}>Show graph</Button>
          <Button
            type="default"
            icon={<SettingOutlined/>}
            onClick={() => setIsSettingsShown(true)}
          >
            Settings
          </Button>
        </div>
      </div>
      <Row gutter={[64, 64]} className={css.content}>
        <Col span={12}>
          <Card title="Money loss per problem">
            <Donut data={dashboardData.moneyLoss} colorField="name" angleField="value"/>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Top 5 problems, number of cases and money loss">
            <ColumnChart data={dashboardData.top5BadNodes} xField="type" yField="value" seriesField="name"/>
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Engagement chart (how deeply visitors engaged with the product)">
            <BubbleChart
              data={dashboardData.statisticOfVariants}
              xField="count"
              yField="deepNumber"
              sizeField="countSum"
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Share of problem per Segment or Variant">
            <TreeMap data={dashboardData.top3ProblemVariants} colorField="type"/>
          </Card>
        </Col>
        <Col span={24}>
          <ErrorBoundary fallback={<p>Something went wrong</p>}>
            <ProjectTable graphData={graphData} loading={isGraphLoading}/>
          </ErrorBoundary>
        </Col>
      </Row>
      {isSettingsShown && (
        <ProjectSettings
          open={isSettingsShown}
          project={currentProject}
          onClose={() => setIsSettingsShown(false)}
        />
      )}
    </div>
  );
}

export default withRouter(observer(ProjectDashboard));

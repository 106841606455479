import { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import notification from 'antd/es/notification';

import { CustomInput } from '../../atoms/custom-input';
import { CustomButton } from '../../atoms/custom-button';
import { useStore } from '../../../services/stores/use-store';

import styles from '../../organisms/auth-form/form-items.module.scss';
import s from './user-form.module.scss';

export const UserForm = ({ user }: { user: { email: string, name: string | null } }) => {
	const {
		teamStore: { updateProfile, isTeamLoading },
	} = useStore();

	const [name, setName] = useState<string>('');

	useEffect(() => {
		if (user.name) {
			setName(user.name);
		}
	}, [user.name]);

	const save = useCallback(() => {
		updateProfile({ name })
			.then((result) => {
				if (result.status === 200) {
					notification.success({
						message: 'Success',
						description: 'Profile updated',
					});
					return;
				}

				notification.error({
					message: 'Error',
					description: 'Something went wrong',
				});
			});
	}, [name]);

	return (
		<div className={cn(s.userForm)}>
			<CustomInput
				labelClassName={cn(styles.formItem, styles.lastItem)}
				onChangeString={setName}
				type="user"
				placeholder="Name"
				name="name"
				value={name}
				withIcon
			/>
			<CustomInput
				labelClassName={cn(styles.formItem, styles.lastItem)}
				type="e-mail"
				placeholder="e-mail"
				name="email"
				withIcon
				value={user.email}
				isDisabled
			/>
			<CustomButton
				text="Save"
				size="large"
				type="default"
				onClick={save}
				isDisabled={!name || isTeamLoading}
				isLoading={isTeamLoading}
			/>
		</div>
	);
};

import { localStorageCheck } from './local-storage-check';

export const getFromLocalStorage = <T>(name: string): T | null => {
	if (localStorageCheck()) {
		const itemStr = window.localStorage?.getItem(name)
		if (itemStr) {
			return JSON.parse(itemStr) as T;
		}
	}

	return null;
};

import { CustomInput } from '../../atoms/custom-input';
import styles from '../../organisms/auth-form/form-items.module.scss';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../atoms/custom-button';
import { SocialBlock } from '../social-block';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../services/stores/use-store';
import { RequestStatusNotification } from '../request-status-notification';
import { withRouter } from '../../../services/hooks/withRouter';

const SignUpForm = () => {
	const { authStore } = useStore();
	const { setAuthState, letSignUp } = authStore;
	const [email, setEmail] = useState<string>('');
	const [isDisabledEmail, setIsDisabledEmail] = useState<boolean>(false);
	const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
	const [sendForm, setSendForm] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [errorEmail, setErrorEmail] = useState<boolean>(false);
	const [statusEmailMessage, setStatusEmailMessage] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (sendForm) {
			setIsLoading(true);
			letSignUp(email).then(({ status, message }) => {
				if (status) {
					if (status !== 200 && message) {
						setStatusEmailMessage(message);
						setErrorEmail(true);
					} else {
						setSuccess(true);
						setIsDisabledEmail(true);
					}
				}
				setIsLoading(false);
			});
			setEmail('');
		}
	}, [sendForm]);

	useEffect(() => {
		let changeErrorTimeOut: NodeJS.Timeout;
		if (errorEmail) {
			const clearError = function clearStatus() {
				setErrorEmail(false);
				setStatusEmailMessage('');
				setSendForm(false);
				setIsDisabledEmail(false);
				clearTimeout(changeErrorTimeOut);
			};
			changeErrorTimeOut = setTimeout(clearError, 2500);
		}
	}, [errorEmail]);

	useEffect(() => {
		if (email.length > 7) {
			setIsDisabledButton(false);
		}
	}, [email]);

	return (
		<>
			{sendForm && (
				<RequestStatusNotification
					text="Activation email was sent. Please check your inbox"
					isLoading={isLoading}
					showSuccess={success && statusEmailMessage.length === 0}
				/>
			)}

			<CustomInput
				labelClassName={styles.formItem}
				onChangeString={setEmail}
				type="email"
				placeholder="e-mail"
				name="e-mail"
				value={email}
				withIcon
				isDisabled={isDisabledEmail}
				error={errorEmail}
				errorMessage={errorEmail && statusEmailMessage.length > 0 ? statusEmailMessage : undefined}
			/>
			<CustomButton
				isDisabled={isDisabledButton}
				type="default"
				className={styles.signInButton}
				text="Sign Up"
				onClick={() => {
					setSendForm(true);
					setIsDisabledEmail(true);
					setIsDisabledButton(true);
				}}
			/>
			<SocialBlock setFormType={setAuthState} formType="Sign In" />
		</>
	);
};

export default withRouter(observer(SignUpForm));

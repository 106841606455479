import s from './social-block.module.scss';
import { SocialButtons } from '../social-buttons';
import { SignUpButton } from '../../atoms/sign-up-button';

interface SocialBlockProps {
	setFormType: (value: string) => void;
	formType: string;
}

export const SocialBlock = ({ setFormType, formType }: SocialBlockProps) => (
	<div className={s.quickBlock}>
		<SocialButtons />
		<SignUpButton setFormType={setFormType} formType={formType} />
	</div>
);

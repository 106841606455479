import Spin from 'antd/es/spin';
import { NumberFormat } from '../../../services/common/number-format';
import { StatisticItem } from '../../atoms/statistic-item';
import { GraphData } from '../../../types/interface/graphData';

import css from '../../../pages/project/components/ProjectGraph/ProjectGraph.module.scss';

const ProjectStatistic = ({graphData, loading}: {graphData?: GraphData | null, loading?: boolean}) => {
	return loading ? <Spin size="large" /> : (
		<div className={css.statItems}>
			<span className={css.cases}>{`${NumberFormat({
				item: graphData?.aggregate_info?.cases || 0,
				fraction: 0,
			})} Cases`}</span>
			{/*<StatisticItem
				className={css.statItem}
				icon="findOperationLeaks"
				value={graphData?.aggregate_info?.nodes || 0}
			/>*/}
			<StatisticItem className={css.statItem} icon="unique" value={graphData?.aggregate_info?.nodes || 0} />
			<StatisticItem className={css.statItem} icon="problems" value={graphData?.aggregate_info?.problems || 0} />
			<StatisticItem className={css.statItem} icon="errors" value={graphData?.aggregate_info?.bottle_necks || 0} />
		</div>
	);
};

export default ProjectStatistic;
